import { Fragment } from "react"
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import classes from './Candidate.module.css'
import { useSelector, useDispatch } from 'react-redux';
import { getCandidatePublicProfile } from '../../store/candidateProfile-actions';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";



const CandidatePublicView = ()=>{

    const {candidateId} = useParams()
    const dispatch = useDispatch()

    const publicProfile = useSelector(state=>(state.candidateProfile.candidateDetails))



    useEffect(()=>{
        const fetchDetails = async ()=>{
            await dispatch(getCandidatePublicProfile(candidateId))
        }
        
        fetchDetails()
        
    }, [])

    


    

    return (
        <Fragment>
            <CssBaseline />
      {publicProfile.first_name&&<Container fixed>
        <Box sx={{ marginTop: '4em' }}>
            <div className={classes.title}>Profile</div>
        </Box>
        <Box sx={{ marginTop: '4em'}}>
            <Box sx={{ marginTop: '2em', display: 'inline-flex', width:'70%', marginLeft:'auto', marginRight:'auto' }}>
                <span>{publicProfile.email}</span>
                <span>{publicProfile.first_name}</span>
                <span>{publicProfile.last_name}</span>
                
            </Box>
            
        </Box>
      </Container>}
        </Fragment>
    )
}

export default CandidatePublicView