import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { cleanModal } from '../../store/modal-action';
import { useDispatch } from 'react-redux';
import ErrorBot from '../../error_bot.svg';


export default function ModalMessage({message}) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    
    setOpen(false)
    dispatch(cleanModal())
  
  };

  return (
    <div style={{width:'40%', marginLeft:'auto', marginRight:'auto'}}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #f55e49',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          {message}
          </Typography>
        </Box>
      </Modal>
      {/* <img style={{width:'100%'}}  src={ErrorBot} alt="Error" />
      <div style={{width: '100%', textAlign:'center', fontSize:'1.5em', marginTop:'3em'}}>
          {message}
        </div> */}
    </div>
  );
}