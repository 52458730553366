import { Fragment } from 'react';
import PublicHeader from '../Header/PublicHeader'
import RecruiterMainHeader from '../Header/RecruiterMainHeader';

const MarketingLayout = (props) => {
  return (
    <Fragment>
      <RecruiterMainHeader />
      <main>{props.children}</main>
    </Fragment>
  );
};

export default MarketingLayout;
