import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getDefaultPayment, getSubscriptions } from '../../store/companyProfile-actions';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CompanyCardInfo from './CompanyCardInfo';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { subscribeAccount } from "../../store/companyProfile-actions";
import classes from './Company.module.css'


const CompanySubscription = (props) => {
    const dispatch = useDispatch();
    const stripePromise = loadStripe('pk_test_51Mwl1zGjI5m9XztINrEq107Las1svApslotokXI6o04qs99fYVAoErD5PS1hiT0d7cdKSoeXPKFL2iKgrez3Y35m006ejXbOEi');
    var d = new Date(0);

    const error = useSelector((state) => state.companyProfile.error);
    const loading = useSelector((state) => state.companyProfile.loading);
    const card = useSelector((state) => state.companyProfile.companyPaymentDetails);
    const subscriptionDetails = useSelector((state) => state.companyProfile.companySubscriptionDetails);

    useEffect(() => {
        dispatch(getDefaultPayment(props.company_id))
        dispatch(getSubscriptions(props.company_id))
    }, []);

    const transformDate = (date) => {
        return new Date(date * 1000).toLocaleDateString("en-US")
    }
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    // Create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
  
    if (error) {
      console.log('[error]', error);
    } else {
      dispatch(subscribeAccount(props.company_id, paymentMethod))
    }
  };

  if (loading){
    return (
      <Fragment>
                    <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
          <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
          <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
          <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
          <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
          <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
          <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
          
      </Stack>
      </Fragment>
    )
  }

  if (error){
    return (
      <Fragment>
          <h1>Something went wrong</h1>
      </Fragment>
    )
  }

  if (!subscriptionDetails && loading){
    return (
      <Fragment>
          <h1>Sorry, we could not get your subscription</h1>
      </Fragment>
    )
  }


return (
    <Fragment>
        <Container fixed>
            <Box sx={{ marginTop: '4em', paddingBottom: '4em' }}>
            <Box sx={{ marginTop: '2em', display: 'inline-flex', width:'50%', marginLeft:'auto', marginRight:'auto', display:'block'}}>                <Box sx={{display: 'block', marginLeft:'3em', width:'100%'}}></Box>
            
        
        {subscriptionDetails && <div style={{marginBottom:'4em'}}>
        <h2>Active subscriptions</h2>
            <div>{subscriptionDetails.plan.metadata.name}</div>
            <div>${(subscriptionDetails.plan.amount/100).toFixed(2)}</div>
            <div>Next charge date: {transformDate(subscriptionDetails.current_period_end)}</div>
    </div>}
    {!subscriptionDetails &&
    <Box>
      <div style={{fontSize:'1.5em', fontWeight:'600', marginBottom:'2em'}}>Subscribe to hyrewiz and start hiring</div>
      <div style={{marginBottom:'2em'}}>
        <div style={{fontSize:'20px', fontWeight:'500'}}>Hyrewiz Pro</div>
        <div style={{fontSize:'14px'}}>Unlimited job requests</div>
        <div style={{fontSize:'14px'}}>$19.99/month</div>
      </div>
      <form onSubmit={handleSubmit} style={{width:'100%'}}>
            <CardElement />
            <button className={classes.button} type="submit" disabled={!stripe} style={{marginTop:'2em'}}>
                Subscribe
            </button>
            </form>
      </Box>}

    {card && card.card&&subscriptionDetails&&<div style={{marginBottom:'2em'}}>
        <h2>Default Card</h2>
            <div style={{marginBottom:'4em'}}>
              {`${card.card.brand.toUpperCase()} **** **** **** ${card.card.last4} - Exp: ${card.card.exp_month}/${card.card.exp_year}`}
              </div>
            <Elements stripe={stripePromise}>
    <CompanyCardInfo company_id={props.company_id}/>
    </Elements>
    </div>}
    
    </Box>
    </Box>
    </Container>

    </Fragment>
);
};

export default CompanySubscription;
