import { Fragment } from "react"
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import classes from './Company.module.css'
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { companyGetFullProfile, updateCompanyProfile } from '../../store/companyProfile-actions';
import { useEffect, useState } from "react";
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CompanyCardInfo from "./CompanyCardInfo";
import CompanySubscription from "./CompanySubscription";
import { loadStripe } from '@stripe/stripe-js';




const CompanyProfile = ()=>{

  var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
  const LOCAL_DOMAIN = "http://0.0.0.0:8000"
  const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

  const stripePromise = loadStripe('pk_test_51Mwl1zGjI5m9XztINrEq107Las1svApslotokXI6o04qs99fYVAoErD5PS1hiT0d7cdKSoeXPKFL2iKgrez3Y35m006ejXbOEi');

  if (process.env.REACT_APP_ENV==='local'){
    BASE_DOMAIN=LOCAL_DOMAIN
  }
  else if (process.env.REACT_APP_ENV==='test'){
    BASE_DOMAIN=TEST_DOMAIN
  }

    const dispatch = useDispatch()
    const companyDetails = useSelector((state) => state.companyProfile.companyProfileDetails);
    const error = useSelector((state) => state.companyProfile.error);
    const loading = useSelector((state) => state.companyProfile.loading);
    const [compEmail, setCompEmail] = useState("")
    const [compName, setCompName] = useState("")
    const [file, setFile] = useState(null);


    const fetchData = async () => {
      await dispatch(companyGetFullProfile());
      
  };

    useEffect(() => {
        
        fetchData();

      }, [dispatch]);


    useEffect(() => {
      if (companyDetails){
        setCompEmail(companyDetails.email)
        setCompName(companyDetails.company_name)
      }
    }, [companyDetails]);


    const handleEditCompany = async (event)=>{
        event.preventDefault()

        if (file) {
            const formData = new FormData();
            formData.append('profile_picture', file);
      
            try {
              const response = await axios.post(`${BASE_DOMAIN}/account/company/${localStorage.getItem('company_user_id')}/image/`, formData);
            } catch (error) {
              console.error('Error uploading profile picture:', error);
            }
          }

        dispatch(updateCompanyProfile(compEmail,compName))
    }

    const handleCompEmailChange =(event) =>{
        event.preventDefault()
        setCompEmail(event.target.value)
    }

    const handleCompNameChange =(event) =>{
        event.preventDefault()
        setCompName(event.target.value)
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
      };

    if (loading){
      return (
        <Fragment>
                      <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            
        </Stack>
        </Fragment>
      )
    }

    if (error){
      return (
        <Fragment>
            <h1>Something went wrong</h1>
        </Fragment>
      )
    }

    if (!companyDetails){
      return (
        <Fragment>
            <h1>Sorry, we could not get your profile</h1>
        </Fragment>
      )
    }


    return (
        <Fragment>
            <CssBaseline />
      <Container fixed>
        <Box sx={{ marginTop: '4em', paddingBottom: '4em' }}>
            <Box sx={{ marginTop: '2em', display: 'inline-flex', width:'70%', marginLeft:'auto', marginRight:'auto' }}>
                <Box sx={{display: 'block', marginLeft:'3em', width:'100%'}}>
                <div>
                        <input type="file" onChange={handleFileChange} />
                </div>
                    <TextField onChange={handleCompEmailChange} className={classes.profileInputs} id="outlined-search" label="Email" value={compEmail} />
                    <TextField onChange={handleCompNameChange} className={classes.profileInputs} id="outlined-search" label="First name" value={compName} />
                    <TextField className={classes.profileInputs} id="outlined-search" label="Password" value="randomnum" type="password" />
                    <button onClick={handleEditCompany} className={classes.button}>
                        <span>Save</span>
                    </button>
                </Box>
            </Box>
            
        </Box>
      </Container>

        </Fragment>
    )
}

export default CompanyProfile