import { createSlice } from '@reduxjs/toolkit';

const recruiterProfileSlice = createSlice({
  name: 'recruiterProfile',
  initialState: {
    recruiterProfileDetails:null,
    recruiterPublicDetails:{},
    recruiterPaymentDetails:{},
    recruiterConnectAccount:{},
    recruiterSubscriptionDetails:null,
    recruiterLoggedIn: false,
    recruiterSignedUp: false,
    loading: false,
    error: null,
  },
  reducers: {
    logOut(state, action) {
        state.recruiterProfileDetails = {}
        state.recruiterLoggedIn = false
    },
    logIn(state, action) {
        state.recruiterLoggedIn = action.payload.recruiterLoggedIn
    },
    signUp(state, action) {
        state.recruiterSignedUp = action.payload.recruiterSignedUp
    },
    recruiterFullProfile(state, action) {
      state.recruiterProfileDetails = action.payload.recruiterProfileDetails
      state.loading = false
    },
    recruiterGetPayment(state, action) {
      state.recruiterPaymentDetails = action.payload.paymentDetails
      state.loading = false
    },
    recruiterGetSubscription(state, action) {
      state.recruiterSubscriptionDetails = action.payload.subscriptionDetails
      state.loading = false
    },
    autoSignIn(state, action) {
      state.recruiterLoggedIn = true
    },
    recruiterPublicProfile(state, action) {
      state.recruiterPublicDetails = action.payload.recruiterPublicDetails
    },
    getDataBegin(state, action) {
      state.loading = true
    },
    getProfileError(state, action) {
      state.loading = false
      state.error = action.payload.error
    },
    getPaymentDataError(state, action) {
      state.loading = false
      state.recruiterPaymentDetails = null
    },
    getSubscriptionDataError(state, action) {
      state.loading = false
      state.recruiterSubscriptionDetails = null
    },
    getConnectAccount(state, action) {
      state.loading = false
      state.recruiterConnectAccount = action.payload.connectAccount
    }
  },
});

export const recruiterProfileActions = recruiterProfileSlice.actions;

export default recruiterProfileSlice;
