import { Fragment } from "react";
import * as React from 'react';
import ListingsContainer from "../Listing/ListingsContainer";


const CompanyListings=()=> {

    return (
        <Fragment>
            <ListingsContainer />
        </Fragment>
    );
}

export default CompanyListings