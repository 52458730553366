import {useParams, useNavigate, Link} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { getPipelinesList } from "../../store/companyListings-action";
import { Fragment } from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import classes from './Listing.module.css'
import CandidateCard from "./CandidateCard";





const ListingAllPipelines = ()=>{
    const navigate = useNavigate()
    const {listingId} = useParams()
    const dispatch = useDispatch()

    const pipelinesList = useSelector(state=>(state.companyListings.pipelinesList))
    const loading = useSelector(state=>(state.companyListings.loading))
    const error = useSelector(state=>(state.companyListings.error))

    useEffect(()=>{
        const fetchData = async ()=>{
            await dispatch(getPipelinesList(listingId))
        }
        fetchData()
    },[dispatch, listingId])


    const navToPipeline = (id)=>{
        navigate(`/company/pipeline/${id}`)
    }

    if (loading) {
        return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="rectangular" width="100%" height={60} />
        
        <Skeleton variant="rounded" width="100%" height={60}  />
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60}  />
    </Stack>
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      if (!pipelinesList || pipelinesList.length===0) {
        return (
            <Box className={classes.listingsContainer}>
            <div className={classes.noListingsContainer}>
                        <h3>No candidates found</h3>
                    </div>
                    </Box>
        )
      }
    


    return (
        <Fragment>
        <Box style={{display:"inline-flex", width:"100%", marginTop:"3em"}}>

        <Box className={classes.listingsContainer}>
        
            {pipelinesList.length>0&&
            <div>
                <div className={classes.jobDescriptionTitle}>Active candidates</div>
                {
                    pipelinesList.map((candidate)=>{
                        return (
                            <Box key={candidate.id} className={classes.listingCard} onClick={() => navToPipeline(candidate.id)}>
                                <CandidateCard key={candidate.id} {...candidate} />
                            </Box>
                        )
                    })
                }
            </div>
            }
        
        </Box>
        
        </Box>
        
        </Fragment>
        )

}

export default ListingAllPipelines