import { Fragment } from 'react';
import RecruiterMainHeader from '../Header/RecruiterMainHeader'
import CompanyMainHeader from '../Header/CompanyMainHeader';
import CandidateMainHeader from '../Header/CandidateMainHeader';

const Layout = (props) => {
  return (
    <Fragment>
      {props.companyActive&&<CompanyMainHeader />}
      {props.recruiterActive&&<RecruiterMainHeader />}
      {props.candidateActive&&<CandidateMainHeader />}
      <main>{props.children}</main>
    </Fragment>
  );
};

export default Layout;
