import { createSlice } from '@reduxjs/toolkit';

const candidateProfileSlice = createSlice({
  name: 'candidateProfile',
  initialState: {
    candidateDetails:{},
    pipeline:{},
    pipelineActive:null,
  },
  reducers: {
    candidatePublicProfile(state, action) {
      state.candidateDetails = action.payload.candidateDetails
    },
    activatePipeline(state, action) {
      state.pipelineActive = action.payload.pipeline
    }

  },
});

export const candidateProfileActions = candidateProfileSlice.actions;

export default candidateProfileSlice;
