import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Listing.module.css';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListingPaymentDetails, updateListingPayment } from '../../store/companyListings-action';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {useNavigate, useParams} from "react-router-dom";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


const ListingPayment = () => {
    const {listingId} = useParams()
    const dispatch = useDispatch()

    const [paymentAmount, setPaymentAmount] = useState("")
    const [paymentType, setPaymentType] = useState("")
    const [paymentPeriod, setPaymentPeriod] = useState("")

    const listingPaymentRes = useSelector(state=>(state.companyListings.listingPaymentDetails))
    const loading = useSelector((state) => state.companyListings.loading);
    const error = useSelector((state) => state.companyListings.error);

    useEffect(()=>{
        const fetchData = async ()=>{
            await dispatch(getListingPaymentDetails(listingId))
        }
        fetchData()
    },[dispatch])

    useEffect(()=>{
        if(listingPaymentRes.total_amount){
            setPaymentAmount(listingPaymentRes.total_amount)
            setPaymentPeriod(listingPaymentRes.payment_period)

            if(listingPaymentRes.payment_type === 0){
                setPaymentType("%")

            }else{
                setPaymentType("$")
            }
        }
    },[listingPaymentRes])

  
      const handlePaymentAmountChange = (event) =>{
          event.preventDefault()
          setPaymentAmount(event.target.value)
      }
  
      const handlePaymentTypeChange = (event) =>{
          event.preventDefault(event.target.value)
          setPaymentType(event.target.value)
      }

        const handlePaymentPeriodChange = (event) =>{
            event.preventDefault(event.target.value)
            setPaymentPeriod(event.target.value)
        }

    
        const handlePaymentSubmit = (event) =>{
            event.preventDefault()
            dispatch(updateListingPayment(listingId, paymentType, paymentPeriod, paymentAmount))
        }

  

    const paymentTypes = {"%":0, "$":1};
    const paymentPeriods = [1,2,3];


    if (loading) {
        return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="rectangular" width="100%" height={60} />
        
        <Skeleton variant="rounded" width="100%" height={60}  />
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60}  />
    </Stack>
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      if (!listingPaymentRes) {
        return null;
      }


    return (
        <Fragment>
        <Container>
            <Box className={classes.listingFormStepOne}
                component="form"
                noValidate
                autoComplete="off"
        >
        <div className={classes.formFieldGroup}>
        <TextField
          id="paymentAmount"
          label="Payment Amount"
          type="number"
          value={paymentAmount}
          className={classes.listingFormFullField}
          style={{marginRight:"8px"}}
          onChange={handlePaymentAmountChange}
          
        />
        <TextField
          id="paymentType"
          select
          label="Payment type"
          value={paymentType}
          className={classes.listingFormFullField}
          onChange={handlePaymentTypeChange}
        >
          {Object.keys(paymentTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        </div>
        <TextField
          id="paymentPeriod"
          select
          label="Payment period"
          value={paymentPeriod}
          className={classes.listingFormFullField}
          onChange={handlePaymentPeriodChange}
        >
          {paymentPeriods.map((period) => (
            <MenuItem key={period} value={period}>
              {period}
            </MenuItem>
          ))}
        </TextField>


<button  className={classes.button} onClick={handlePaymentSubmit}>
      <span>Update payment</span>
    </button>
                
    </Box>




        </Container>
        </Fragment>
    )
}

export default ListingPayment