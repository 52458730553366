import { companyListingActions } from './companyListings-slice';

var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
const LOCAL_DOMAIN = "http://0.0.0.0:8000"
const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

if (process.env.REACT_APP_ENV==='local'){
  BASE_DOMAIN=LOCAL_DOMAIN
}
else if (process.env.REACT_APP_ENV==='test'){
  BASE_DOMAIN=TEST_DOMAIN
}



  export const getCompanyListings = () => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyListingActions.getListingBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/listing/list`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyListingsList = await fetchData();
          dispatch(companyListingActions.companyGetListingsList(
            {listingsList:companyListingsList.listings}
          ))
      } catch (error) {
        dispatch(companyListingActions.getListingError(
          {error:error}
        ))
      }
    };
  };


  export const getListingDetails = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyListingActions.getListingBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/listing/${listingId}/details`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyListingDetails = await fetchData();
          await dispatch(getListingContracts(listingId))
          await dispatch(getAllListingContracts(listingId))
          await dispatch(companyListingActions.companyGetListingDetails(
            {listingDetails:companyListingDetails}
          ))
      } catch (error) {
        dispatch(companyListingActions.getListingError(
          {error:error}
        ))
      }
    };
  };

  export const cleanListingDetails = ()=>{
    return async(dispatch) =>{
        dispatch(companyListingActions.companyCleanListingDetails())
    }
    
  }

  export const createListing = (title, city, state, country, workplaceType, jobType, listingDepartment, paymentAmount, paymentType, paymentPeriod) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const postData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/listing/`,{
            method: 'POST',
            headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
            body: JSON.stringify({ title: title, city:city, state:state, 
                country:country, employment_type:jobType, workplace_type:workplaceType, department: listingDepartment, payment_type:paymentType, total_amount:paymentAmount, payment_period:paymentPeriod})
        }
        );
  
        if (!response.ok) {
          throw new Error('Could not fetch  data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const listingData = await postData();
        dispatch(companyListingActions.companySetListingId(
            {listingId:listingData.id}
          ))
      } catch (error) {
      }
    };
  };


  export const updateListing = (listingId, description, qualifications, salaryRange, jobLinks, jobType, workplaceType) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const postData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/listing/${listingId}/details`,{
            method: 'PUT',
            headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
            body: JSON.stringify({ description: description, qualifications:qualifications, 
                salary_range:salaryRange, job_links:jobLinks, employment_type:jobType, workplace_type:workplaceType})
        }
        );
  
        if (!response.ok) {
          throw new Error('Could not fetch  data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        await postData();

      } catch (error) {
      }
    };
  };


  export const publishListing = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const postData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/listing/${listingId}/publish`,{
            method: 'POST',
            headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
        }
        );
  
        if (!response.ok) {
          throw new Error('Could not fetch  data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const listingData = await postData();
        dispatch(companyListingActions.companyGetListingDetails(
            {listingDetails:listingData}
          ))
      } catch (error) {
      }
    };
  };

  export const unPublishListing = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const postData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/listing/${listingId}/unpublish`,{
            method: 'POST',
            headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
        }
        );
  
        if (!response.ok) {
          throw new Error('Could not fetch  data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const listingData = await postData();
        dispatch(companyListingActions.companyGetListingDetails(
            {listingDetails:listingData}
          ))
      } catch (error) {
      }
    };
  };

  export const getListingContracts = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/${listingId}`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyListingContracts = await fetchData();
          dispatch(companyListingActions.companyGetListingContracts(
            {listingContracts:companyListingContracts.contracts}
          ))
      } catch (error) {
      }
    };
  };

  export const approveListingAccess = (contractId, status) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/${contractId}/associate/recruiter`,{ 
              method: 'post', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ status: status})
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }  
  
        return;
      };
  
      try {
        await fetchData();
        
      } catch (error) {
      }
    };
  };


  export const getListingPipelines = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/${listingId}/pipeline`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get pipelines!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const pipelines = await fetchData();
          dispatch(companyListingActions.getListingPipelines(
            {listingPipelines:pipelines.pipelines}
          ))
      } catch (error) {
      }
    };
  };

  
  export const getAllListingContracts = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/${listingId}/all`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyListingContracts = await fetchData();
          dispatch(companyListingActions.companyGetAllListingContracts(
            {listingContracts:companyListingContracts.contracts}
          ))
      } catch (error) {
      }
    };
  };


  export const getContractDetails = (contractId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/${contractId}/details`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyContractDetails = await fetchData();
          dispatch(companyListingActions.companyGetContractDetails(
            {contractDetails:companyContractDetails}
          ))
      } catch (error) {
      }
    };
  };


  export const getContractsList = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyListingActions.getContractsListBegin())
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/${listingId}`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyContractsList = await fetchData();
          dispatch(companyListingActions.companyGetContractsList(
            {contractsList:companyContractsList.contracts.approved}
          ))
      } catch (error) {
        dispatch(companyListingActions.getContractsListError({
          error : error
        }))
      }
    };
  };


  export const getRequestsList = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyListingActions.getContractsListBegin())
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/${listingId}`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyContractsList = await fetchData();
          dispatch(companyListingActions.companyGetRequestsList(
            {requestsList:companyContractsList.contracts.pending}
          ))
      } catch (error) {
        dispatch(companyListingActions.getContractsListError({
          error : error
        }))
      }
    };
  };



  export const getPipelinesList = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyListingActions.getPipelineListBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/${listingId}/pipeline`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyPipelinesList = await fetchData();
          dispatch(companyListingActions.companyGetPipelinesList(
            {pipelinesList:companyPipelinesList.pipelines}
          ))
      } catch (error) {
        dispatch(companyListingActions.getPipelineListError(
          {error: error}));
      }
    };
  };

  export const getListingPipeline = (pipelineId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/pipeline/${pipelineId}`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const pipelineDetails = await fetchData();
          dispatch(companyListingActions.companyGetPipelineDetails(
            {pipelineDetails:pipelineDetails}
          ))
      } catch (error) {
      }
    };
  };

  export const updatePipelineStatus = (pipelineId, status) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/pipeline/${pipelineId}/status`,{ 
              method: 'put', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ status: status})
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }
  
        return;
      };
  
      try {
        await fetchData();
        await dispatch(getListingPipeline(pipelineId))
      } catch (error) {
      }
    };
  };

  export const approvePipelineHire = (pipelineId, salary=0.0) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/pipeline/${pipelineId}/hire`,{ 
              method: 'put', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ salary: salary})
              
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }
  
        return;
      };
  
      try {
        await fetchData();
        await dispatch(getListingPipeline(pipelineId))
      } catch (error) {
      }
    };
  };


  export const getListingPaymentDetails = (listingId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyListingActions.getListingBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/payment/${listingId}`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const companyListingDetails = await fetchData();
          await dispatch(companyListingActions.companyGetPaymentDetails(
            {paymentDetails:companyListingDetails}
          ))
      } catch (error) {
        dispatch(companyListingActions.getListingError(
          {error:error}
        ))
      }
    };
  };


  export const updateListingPayment = (listingId, paymentType, paymentPeriod, paymentAmount) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyListingActions.getListingBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/payment/${listingId}`,{ 
              method: 'put', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ payment_type: paymentType, payment_period: paymentPeriod, total_amount: paymentAmount})
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        await fetchData();
        await dispatch(getListingPaymentDetails(listingId))

      } catch (error) {
        dispatch(companyListingActions.getListingError(
          {error:error}
        ))
      }
    };
  };



export const sendPayment = (company_id, amount) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyListingActions.getListingBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/payment/company/${company_id}/pay/22`,{ 
              method: 'post', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ amount: amount})
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not send payment!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        await fetchData();

      } catch (error) {
        console.log(error)
      }
    };
  };