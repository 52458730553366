import { configureStore } from '@reduxjs/toolkit';
import companyProfileSlice from './companyProfile-slice';
import companyListingSlice from './companyListings-slice';
import recruiterProfileSlice from './recruiterProfile-slice';
import recruiterListingSlice from './recruiterListings-slice';
import candidateProfileSlice from './candidateProfile-slice';
import modalSlice from './modal-slice';

const store = configureStore({
  reducer: { 
    recruiterProfile: recruiterProfileSlice.reducer,
    companyProfile: companyProfileSlice.reducer,
    companyListings: companyListingSlice.reducer, 
    publicListings: recruiterListingSlice.reducer, 
    candidateProfile: candidateProfileSlice.reducer ,
    modal: modalSlice.reducer,
  },
});

export default store;