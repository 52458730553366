import { useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { candidateSignIn } from "../../store/candidateProfile-actions"


const CandidateTokenAuth = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {token} = useParams()

    useEffect(()=>{
        const fetchToken = async () =>{
            await dispatch(candidateSignIn(token))
            navigate('/candidate')
        }
        fetchToken()
    }
    , [])

    return(
        <div></div>
    )
}

export default CandidateTokenAuth