import { candidateProfileActions } from './candidateProfile-slice';
import { modalActions } from './modal-slice';

var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
const LOCAL_DOMAIN = "http://0.0.0.0:8000"
const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

if (process.env.REACT_APP_ENV==='local'){
  BASE_DOMAIN=LOCAL_DOMAIN
}
else if (process.env.REACT_APP_ENV==='test'){
  BASE_DOMAIN=TEST_DOMAIN
}

export const candidateSignUp = (email, password, firstName, lastName, phone, linkedinUrl) => {
  return async (dispatch) => {
    const postData = async () => {
      const response = await fetch(
        `${BASE_DOMAIN}/account/candidate/`,{
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: email, password:password, first_name:firstName, last_name:lastName, phone:phone, linkedin_url: linkedinUrl })
      }
      );

      if (!response.ok) {
        throw new Error('Could not fetch  data!');
      }

      const data = await response.json();

      return data;
    };

    try {
      const profileData = await postData();
      dispatch(
        candidateProfileActions.candidatePublicProfile({
          candidateDetails: profileData.profile,
        })
      );
    } catch (error) {
    }
  };
};

export const candidateAuth = (email) => {
  return async (dispatch) => {
    const postData = async () => {
      const response = await fetch(
        `${BASE_DOMAIN}/account/candidate/auth`,{
          method: 'GET',
          headers: new Headers({
            "email":email
          }),
      }
      );

      if (!response.ok) {
        throw new Error('Could not fetch  data!');
      }

      const data = await response.json();

      return data;
    };

    try {
      const data = await postData();
      await dispatch(modalActions.setModalMessage({ message: "We sent you an email with sign in details" }));
    } catch (error) {
    }
  };
};
  
    export const getCandidatePublicProfile = (candidateId) => {
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/account/candidate/${candidateId}/public`,{ 
                method: 'get', 
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const profileData = await fetchData();
          dispatch(
            candidateProfileActions.candidatePublicProfile({
              candidateDetails: profileData,
            })
          );
        } catch (error) {
        }
      };
    };

    export const getCandidateProfileDetails = () => {
      const candidateId = localStorage.getItem('can_id')
      const access_t = localStorage.getItem('can_access_token')
      const refresh_t = localStorage.getItem('can_refresh_token')
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/account/candidate/${candidateId}/details`,{ 
                method: 'get',
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t,
                  'Content-Type': 'application/json'
                }), 
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const profileData = await fetchData();
          dispatch(
            candidateProfileActions.candidatePublicProfile({
              candidateDetails: profileData,
            })
          );
        } catch (error) {
        }
      };
    };

    export const activatePipeline = (token) => {
      const access_t = localStorage.getItem('can_access_token')
      const refresh_t = localStorage.getItem('can_refresh_token')
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/contract/pipeline/${token}/activate`,{ 
                method: 'put',
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t,
                  'Content-Type': 'application/json'
                }), 
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const pipelineData = await fetchData();
          dispatch(
            candidateProfileActions.activatePipeline({
              pipeline: pipelineData.active,
            })
          );
        } catch (error) {
        }
      };
    };

    export const getCandidateByToken = (token) => {
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/contract/candidate/pipeline/${token}`,{ 
                method: 'get', 
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const profile = await fetchData();
          localStorage.clear();
          localStorage.setItem('can_id', profile.candidate_id);
        localStorage.setItem('can_access_token', profile.access_token);
        localStorage.setItem('can_refresh_token', profile.refresh_token);
          await dispatch(getCandidateProfileDetails());
          await dispatch(
            candidateProfileActions.activatePipeline({
              pipeline: profile.pipeline_status,
            })
          );
        } catch (error) {
          await dispatch(modalActions.setModalMessage({ message: "Looks like this token has expired" }));
        }
      };
    }

    export const candidateSignIn = (token) => {
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/account/candidate/`,{
                method: 'GET',
                headers: new Headers({
                  "token":token
                }),
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const data = await fetchData();
          localStorage.clear();
          localStorage.setItem('can_id', data.candidate_id);
        localStorage.setItem('can_access_token', data.access_token);
        localStorage.setItem('can_refresh_token', data.refresh_token);
        } catch (error) {
          await dispatch(modalActions.setModalMessage({ message: "Looks like this token has expired" }));
        }
      };
    }

    export const updateCandidateProfile = (candidateId, email, firstName, lastName, phone, linkedinUrl) => {
      const access_t = localStorage.getItem('can_access_token')
      const refresh_t = localStorage.getItem('can_refresh_token')
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/account/candidate/${candidateId}/details`,{
              method: 'put',
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({email:email, first_name:firstName, last_name:lastName, phone:phone, linkedin_url: linkedinUrl })
          }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const profileData = await fetchData();
          dispatch(
            candidateProfileActions.candidatePublicProfile({
              candidateDetails: profileData,
            })
          );
        } catch (error) {
        }
      };
    }