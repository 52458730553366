import Layout from "./Layout"
import {Routes, Route } from "react-router-dom";
import CompanySignIn from "../Auth/CompanySignIn";
import CompanySignUp from "../Auth/CompanySignUp";
import CompanyProfile from "../Company/CompanyProfile";
import CompanyListings from "../Company/CompanyListings";
import ListingsContainer from "../Listing/ListingsContainer";
import ListingDetail from "../Listing/ListingDetail";
import NewListing from "../Listing/NewListing";
import ListingEdit from "../Listing/ListingEdit";
import { useSelector, useDispatch } from 'react-redux';
import { autoSignIn } from '../../store/companyProfile-actions';
import { useEffect, useState } from "react";
import CandidatePublicView from "../Candidate/CandidatePublicView";
import ListingContract from "../Listing/ListingContract";
import ListingAllContract from "../Listing/ListingAllContracts";
import ListingAllPipelines from "../Listing/ListingAllPipelines";
import ListingAllRequests from "../Listing/ListingAllRequests";
import Pipeline from "../Contract/Pipeline";
import ListingPayment from "../Listing/ListingPayment";
import CompanySubscription from "../Company/CompanySubscription";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CompanyPayment from "../Company/CompanyPayment";
import CompanyAccount from "../Company/CompanyAccount";

const Company = () =>{

  const stripePromise = loadStripe('pk_test_51Mwl1zGjI5m9XztINrEq107Las1svApslotokXI6o04qs99fYVAoErD5PS1hiT0d7cdKSoeXPKFL2iKgrez3Y35m006ejXbOEi');

  const dispatch = useDispatch()
  const profile = useSelector((state) => state.companyProfile);
  

    useEffect(() => {
      if(localStorage.getItem('company_user_id')){
        dispatch(autoSignIn());
      }
      }, []);


    return (
        <Layout companyActive={true}>
          <Routes>
            {profile.companyLoggedIn&&<Route index element={<CompanyListings />} />}
            {profile.companyLoggedIn&&<Route path="listings/:listingId" element={<ListingDetail />} />}
            {profile.companyLoggedIn&&<Route path="listings/:listingId/contracts" element={<ListingAllContract />} />}
            {profile.companyLoggedIn&&<Route path="listings/:listingId/candidates" element={<ListingAllPipelines />} />}
            {profile.companyLoggedIn&&<Route path="listings/:listingId/requests" element={<ListingAllRequests />} />}
            {profile.companyLoggedIn&&<Route path="listings/:listingId/payment" element={<ListingPayment />} />}
            {profile.companyLoggedIn&&<Route path="listings/all" element={<CompanyListings />} />}
            {profile.companyLoggedIn&&<Route path="listings" element={<ListingsContainer />} />}
            {!profile.companyLoggedIn&&<Route index element={<CompanySignIn />} />}
            <Route path="signup" element={<CompanySignUp />} />
            <Route path="profile" element={<CompanyProfile />} />
            <Route path="account" element={<CompanyAccount />} />
            <Route path="subscription" element={<Elements stripe={stripePromise}><CompanySubscription company_id={localStorage.getItem('company_user_id')}/></Elements>} /> 
            <Route path="listing/create" element={<NewListing />} />
            <Route path="payment" element={<CompanyPayment company_id={localStorage.getItem('company_user_id')} />} />
            <Route path="listing/edit/:listingId" element={<ListingEdit />} />
            <Route path="candidate/:candidateId" element={<CandidatePublicView />} />
            <Route path="contract/:contractId" element={<ListingContract />} />
            <Route path="pipeline/:pipelineId" element={<Pipeline />} />
            
          </Routes>
        </Layout>
    )
}

export default Company