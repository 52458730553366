import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getCandidateByToken, activatePipeline } from "../../store/candidateProfile-actions"
import { useParams, useSearchParams } from "react-router-dom"
import { Fragment } from "react"
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CandidateSignUp from "../Auth/CandidateSignUp"
import classes from './Candidate.module.css'
import ModalMessage from "../UI/ModalMessage"
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


const CandidateApplication = () => {
    const {token} = useParams()
    const dispatch = useDispatch()

    const candidateDetails = useSelector(state=>(state.candidateProfile.candidateDetails))
    const pipelineActive = useSelector(state=>(state.candidateProfile.pipelineActive))
    const modal = useSelector(state=>(state.modal.modalMessage))
    const [pageLoaded, setPageLoaded] = useState(false)
    const [appStep, setAppStep] = useState(0)

    useEffect(()=>{
        const fetchDetails = async ()=>{
            await dispatch(getCandidateByToken(token))
            setPageLoaded(true)
        }
        fetchDetails()
    }, [])


    return (
        <Fragment>
            <Container>
                {pageLoaded&&
                <Box>
                    {pipelineActive==false&&
                    <div>
                        <div className={appStep==0 ? classes.appActive : classes.appInactive}>
                            <h1 style={{textAlign:'center'}}>Update your account if needed</h1>
                            <CandidateSignUp />
                            <button className={`${classes.button} ${classes.canButton}`} onClick={()=>setAppStep(1)}>Next</button>
                        </div>
                        <div className={appStep==1 ? classes.appActive : classes.appInactive}>
                            <h1 style={{textAlign:'center'}}>Apply for this role</h1>
                            <div>
                            <button className={`${classes.button} ${classes.canButton}`} onClick={()=>dispatch(activatePipeline(token))}>Apply</button>
                            <button className={`${classes.button} ${classes.canButton}`} onClick={()=>setAppStep(0)}>Back</button>
                            </div>
                        </div>
                        
                    </div>
                    }
                {pipelineActive&&
                    <div>
                        <h1>You have already applied for this role</h1>
                        <div>Your recruiter will follow up with you</div>
                    </div>}
                    {modal&&<ModalMessage message={modal} />}       
            </Box>}
            {!pageLoaded&&
            <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            
        </Stack>}
            </Container>
        </Fragment>
    )
}

export default CandidateApplication