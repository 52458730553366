import { Fragment } from "react"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import classes from './Tile.module.css'
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import Box from '@mui/material/Box';
import moment from 'moment';

const ProfileTile = (props) => {

    const navigate = useNavigate();

    const calculateDateDiff = (date) => {
        const now = moment();
        const createDate = moment(date);
        const diffInYears = now.diff(createDate, 'years', true);
     
        if (diffInYears < 1) {
            return (
                <Fragment>
                    <div className={classes.tileTopText}>1</div>
                    <div className={classes.tileBotText}>Year on Hyrewiz</div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className={classes.tileTopText}>{Math.round(diffInYears)}</div>
                    <div className={classes.tileBotText}>Years on Hyrewiz</div>
                </Fragment>
            )
        }
     }

    return(
        <Fragment>
            <Card className={classes.profileCard}>
                <CardContent sx={{padding: "15px", paddingBottom: "0px !important"}}>
                    <Box sx={{display: "inline-flex", width: "100%"}}>
                <Box sx={{display: 'block'}}>
                    <Avatar style={{width:"104px", height:"104px"}} alt="JP" src={props.profile_pic} />
                    <div className={classes.profileTileTitle} color="text.secondary" gutterBottom>
                    {props.name}
                    </div>
                    <div className={classes.profileTileRole} color="text.secondary" gutterBottom>
                    Recruiter
                    </div>
                    </Box>
                    <Box className={classes.profileTileDetails}>
                        <div className={classes.tileText}>
                            <div className={classes.tileTopText}>{props.reviews_count}</div>
                            <div className={classes.tileBotText}>Reviews</div>
                        </div>
                        <hr style={{margin:'0', border:'0', borderTop:'1px solid #DDDDDD'}}/>
                        <div className={classes.tileText}>{calculateDateDiff(props.created_at)}</div>
                    </Box>
                    </Box>
                </CardContent>
            </Card>
        </Fragment>

    )
}

export default ProfileTile;