import {useParams, useNavigate, Link} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { getContractsList } from "../../store/companyListings-action";
import { Fragment } from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import classes from './Listing.module.css'
import ContractCard from "./ContractCard";




const ListingAllContracts = ()=>{
    const navigate = useNavigate()
    const {listingId} = useParams()
    const dispatch = useDispatch()

    const contractsList = useSelector(state=>(state.companyListings.contractsList))
    const loading = useSelector((state) => state.companyListings.loading);
    const error = useSelector((state) => state.companyListings.error);

    useEffect(()=>{
        const fetchData = async ()=>{
            await dispatch(getContractsList(listingId))
        }
        fetchData()
    },[dispatch, listingId])


    const navToContract = (id)=>{
        navigate(`/company/contract/${id}`)
    }

    const testContracts = [
        {
            id: 1,
            candidate_id: 1,
            first_name: "John",
            last_name: "Smith",
        },
        {
            id: 2,
            candidate_id: 2,
            first_name: "Jane",
            last_name: "Doe",
        },
        {
            id: 3,
            candidate_id: 3,
            first_name: "Bob",
            last_name: "Smith",

        },
        {
            id: 4,
            candidate_id: 4,
            first_name: "Sally",
            last_name: "Doe",
        },

    ]


    if (loading) {
        return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="rectangular" width="100%" height={60} />
        
        <Skeleton variant="rounded" width="100%" height={60}  />
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60}  />
    </Stack>
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      if (!contractsList || contractsList.length===0) {
        return (
            <Box className={classes.listingsContainer}>
            <div className={classes.noListingsContainer}>
                        <h3>No open contracts found</h3>
                    </div>
                    </Box>
        )
      }
    


    return (
        <Fragment>
        <Box style={{display:"inline-flex", width:"100%", marginTop:"3em"}}>

        <Box className={classes.listingsContainer}>
        
            {contractsList.length>0&&
            <div>
                <div className={classes.jobDescriptionTitle}>Active contracts</div>
                {
                    contractsList.map((contract)=>{
                        return (
                            <Box key={contract.id} className={classes.contractCard} onClick={() => navToContract(contract.id)}>
                                <ContractCard key={contract.id} {...contract} />
                            </Box>
                        )
                    })
                }
            </div>
            }
        
        </Box>
        
        </Box>
        
        </Fragment>
        )

}

export default ListingAllContracts