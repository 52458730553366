import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { getCandidateProfileDetails } from '../../store/candidateProfile-actions';
import CandidateSignIn from '../Auth/CandidateSignIn';
import Box from '@mui/material/Box';
import ModalMessage from "../UI/ModalMessage"
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const CanidateProfile = () => {

    const dispatch = useDispatch()

    const candidateDetails = useSelector(state=>(state.candidateProfile.candidateDetails))
    const modal = useSelector(state=>(state.modal.modalMessage))

    const [pageLoaded, setPageLoaded] = useState(false)

    const fetchCandidateProfile = async () =>{
        await dispatch(getCandidateProfileDetails())
        setPageLoaded(true)
    }

    useEffect(()=>{
        fetchCandidateProfile()
    }, [])


    return (
        <Fragment>
            {pageLoaded&&<Box sx={{ marginTop: '4em', paddingBottom: '4em' }}>
                {!candidateDetails.id&&<CandidateSignIn />}
                {candidateDetails.id&&
                    <Box>
                        <div>This is logged in</div>
                    </Box>
                    }
                {modal&&<ModalMessage message={modal} />}   
            </Box>}
            {!pageLoaded&&
            <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            
        </Stack>}

        </Fragment>
    )

}

export default CanidateProfile

