import MarketingLayout from "./MarketingLayout"
import { Fragment } from "react"
import classes from './Layout.module.css'
import { useNavigate } from "react-router-dom"

const MarketingPage = () =>{
    const navigate = useNavigate()

    const handleNavToCompany = () =>{
        navigate(`/company`)
    }
    return (
        <Fragment>
            <MarketingLayout />
            <div className={classes.headerText}>
            <button className={classes.createListingButton} onClick={handleNavToCompany}>
                <span>Are you a company?</span>
            </button>
                <div className={classes.mainHeader}>Recruiting, democratized.</div>
                <div className={classes.subHeader}>Become a recruiter for the best companies.</div>
                <div className={classes.subHeader}>On your own terms.</div>

            </div>
            {/* <div style={{position:'absolute', display:'block', height:'130vh', overflowY: 'scroll'}}>
                Test
            </div> */}
        </Fragment>
        
    )
}

export default MarketingPage