import { recruiterListingActions } from './recruiterListings-slice';
import { modalActions } from "./modal-slice";

var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
const LOCAL_DOMAIN = "http://0.0.0.0:8000"
const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

if (process.env.REACT_APP_ENV==='local'){
  BASE_DOMAIN=LOCAL_DOMAIN
}
else if (process.env.REACT_APP_ENV==='test'){
  BASE_DOMAIN=TEST_DOMAIN
}



  export const getPublicListingDetails = (listingId) => {
    const access_t = localStorage.getItem('user_access_token')
    const refresh_t = localStorage.getItem('user_refresh_token')
    return async (dispatch) => {
      dispatch(recruiterListingActions.startGetListing());
      dispatch(recruiterListingActions.getDataBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/listing/${listingId}/public`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const publicListingDetails = await fetchData();
        await dispatch(getContractDetails(listingId))
          dispatch(recruiterListingActions.getPublicListingDetails(
            {listingDetails:publicListingDetails}
          ))
      } catch (error) {
        dispatch(recruiterListingActions.getDataError(
          {error:error}
        ))
      }
    };
  };


  export const requestAccessListing = (listingId) => {
    const access_t = localStorage.getItem('user_access_token')
    const refresh_t = localStorage.getItem('user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/${listingId}/request`,{ 
              method: 'post', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const res = await fetchData();
      } catch (error) {
      }
    };
  };


  export const addCandidateListing = (contractId, email) => {
    const access_t = localStorage.getItem('user_access_token')
    const refresh_t = localStorage.getItem('user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/${contractId}/pipeline`,{ 
              method: 'post', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ candidate_email: email})
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const res = await fetchData();
        if (res.status!=='error'){
          dispatch(getContractPipelines(contractId))
        }
        else{
          dispatch(modalActions.setModalMessage({ message: "Candidate already linked to listing" }));
        }
      } catch (error) {
      }
    };
  };


  export const getContractDetails = (listingId) => {
    const access_t = localStorage.getItem('user_access_token')
    const refresh_t = localStorage.getItem('user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/${listingId}/recruiter`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const contractDetails = await fetchData();
        await dispatch(getContractPipelines(contractDetails.id))
        await dispatch(recruiterListingActions.getContractDetails(
            {contractDetails:contractDetails}
          ))
      } catch (error) {
      }
    };
  };


  export const getContractPipelines = (contractId) => {
    const access_t = localStorage.getItem('user_access_token')
    const refresh_t = localStorage.getItem('user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/${contractId}/pipeline`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get pipelines!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const pipelines = await fetchData();
          dispatch(recruiterListingActions.getContractPipelines(
            {contractPipelines:pipelines.pipelines}
          ))
      } catch (error) {
      }
    };
  };


  export const getAllListings = (queryParams) => {
    const access_t = localStorage.getItem('user_access_token')
    const refresh_t = localStorage.getItem('user_refresh_token')
      // Convert queryParams object to a query string
    var queryString = null
    if (queryParams){
      queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
      };
    

    return async (dispatch) => {
      dispatch(recruiterListingActions.getDataBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/listing/all${queryString ? '?' + queryString : ''}`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const allListings = await fetchData();
          dispatch(recruiterListingActions.getAllListings(
            {listings:allListings.listings}
          ))
      } catch (error) {
        dispatch(recruiterListingActions.getDataError(
          {error:error}
        ))
      }
    };
  }


  export const getRecruiterListings = () => {
    const access_t = localStorage.getItem('user_access_token')
    const refresh_t = localStorage.getItem('user_refresh_token')
    return async (dispatch) => {
      dispatch(recruiterListingActions.getDataBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/contract/listing/recruiter/list`,{ 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not get listings!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const recruiterListingsList = await fetchData();
          dispatch(recruiterListingActions.recruiterGetListingsList(
            {listingsList:recruiterListingsList.listings}
          ))
      } catch (error) {
        dispatch(recruiterListingActions.getDataError(
          {error:error}
        ))
      }
    };
  };