import { Fragment } from "react";
import Box from '@mui/material/Box';
import classes from './Listing.module.css'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import ListingCard from "./ListingCard";
import { getRecruiterListings } from "../../store/recruiterListings-action";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from "react-router-dom";


const RecruiterListingsContainer = (props) => {

    const navigate = useNavigate()

    const recruiterListings = useSelector((state) => state.publicListings.recruiterListingsList)
    const loading = useSelector((state) => state.publicListings.loading);
    const error = useSelector((state) => state.publicListings.error);
    const [page, setPage] = useState(1);
    const [pendingActive, setPendingActive] = useState(false);
    const [publicActive, setPublicActive] = useState(true);
    const [closedActive, setClosedActive] = useState(false);
    const [filteredListings, setFilteredListings] = useState([]);

    const dispatch = useDispatch()

    const fetchData = async ()=>{
        await dispatch(getRecruiterListings())
    }

    useEffect(()=>{
        fetchData()
    }, [])

    useEffect(()=>{
        setFilteredListings(filterListingData("approved"))
    },[recruiterListings])

    const PER_PAGE = 5;

    const count = Math.ceil(filteredListings.length / PER_PAGE);

    const handlePageChange = (event, page)=>{
        setPage(page)
    }

    const handleListingFilter = (event) =>{
        event.preventDefault()
        
        if (event.target.value==="pending"){
            setPendingActive(true)
            setPublicActive(false)
            setClosedActive(false)
        }

        if (event.target.value==="approved"){
            setPendingActive(false)
            setPublicActive(true)
            setClosedActive(false)
        }

        if (event.target.value==="closed"){
            setPendingActive(false)
            setPublicActive(false)
            setClosedActive(true)
        }
        setPage(1)
        setFilteredListings(filterListingData(event.target.value))
        
    }

    const filterListingData = (status) =>{
        if (recruiterListings){
        const tempListings = []
        recruiterListings.map((listing)=>{
            if (listing.status===status){
                tempListings.push(listing)
            }
        })
        return tempListings;
    }
    return []
        
    }
    
    const navToListing = (id)=>{
        navigate(`/user/listings/${id}`)
      }

    
      if (loading) {
        return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="rectangular" width="100%" height={60} />
        
        <Skeleton variant="rounded" width="100%" height={60}  />
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60}  />
    </Stack>
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      if (!recruiterListings) {
        return <Fragment>
            
            <Box className={classes.listingsContainer}>
                <div className={classes.filterButtons}>
                    <button className={publicActive ? classes.buttonActive : ""} value="approved" onClick={handleListingFilter}>Public</button>
                    <button className={pendingActive ? classes.buttonActive : ""} value="pending" onClick={handleListingFilter}>Draft</button>
                    <button className={closedActive ? classes.buttonActive : ""} value="closed" onClick={handleListingFilter}>Closed</button>
                    
                </div>
                <div className={classes.noListingsContainer}>
                        <h3>No listings found</h3>
                    </div>
            </Box>
        </Fragment>;
      }


    return (
        <Fragment>
            
            <Box className={classes.listingsContainer}>
                <div className={classes.filterButtons}>
                    <button className={publicActive ? classes.buttonActive : ""} value="approved" onClick={handleListingFilter}>Public</button>
                    <button className={pendingActive ? classes.buttonActive : ""} value="pending" onClick={handleListingFilter}>Draft</button>
                    <button className={closedActive ? classes.buttonActive : ""} value="closed" onClick={handleListingFilter}>Closed</button>
                    
                </div>
                <div>
                {recruiterListings.length>0&&filteredListings.slice(page*5-5,page*5).map((listing)=>{
                        return(
                            <Box key={listing.id} className={classes.listingCard} onClick={() => navToListing(listing.id)}>
                                <ListingCard key={listing.id} {...listing} />
                            </Box>
                        )
                    })
                }
                {
                    filteredListings.length<1&&<div className={classes.noListingsContainer}>
                        <h3>No listings found</h3>
                    </div>
                }
                </div>
        {
        filteredListings.length>0&&<Stack sx={{margin: "2em 2em 0em 2em;"}} spacing={2}>
            {filteredListings.length>0&&<Pagination 
            count={count}
            page={page} 
            color='second'
            onChange={handlePageChange}
            className={classes.paginationNav} />}
        </Stack>
        }
            </Box>
        </Fragment>
    );
}

export default RecruiterListingsContainer