import Layout from "./Layout"
import {Routes, Route } from "react-router-dom";
import CandidatePublicView from "../Candidate/CandidatePublicView";
import CandidateApplication from "../Candidate/CandidateApplication";
import CandidateProfile from "../Candidate/CandidateProfile";
import CandidateTokenAuth from "../Auth/CandidateTokenAuth";

const Candidate = () =>{


    return (
        <Layout candidateActive={true}>
          <Routes>
            <Route index element={<CandidateProfile />} />
            <Route path=":candidateId" element={<CandidatePublicView />} />
            <Route path="apply/:token" element={<CandidateApplication />} />
            <Route path="auth/:token" element={<CandidateTokenAuth />} />
          </Routes>
        </Layout>
    )
}

export default Candidate