import { companyProfileActions } from './companyProfile-slice';

var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
const LOCAL_DOMAIN = "http://0.0.0.0:8000"
const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

if (process.env.REACT_APP_ENV==='local'){
  BASE_DOMAIN=LOCAL_DOMAIN
}
else if (process.env.REACT_APP_ENV==='test'){
  BASE_DOMAIN=TEST_DOMAIN
}



export const companyLogIn = (email, password) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await fetch(
        `${BASE_DOMAIN}/account/company/`,{ 
            method: 'get', 
            headers: new Headers({
              "email":email,
              "password":password
            })
          }
      );

      if (!response.ok) {
        throw new Error('Could not fetch  data!');
      }

      const data = await response.json();

      return data;
    };

    try {
      const profileData = await fetchData();
        localStorage.clear();
        localStorage.setItem('company_user_id', profileData.id);
        localStorage.setItem('company_user_email', profileData.email);
        localStorage.setItem('company_user_access_token', profileData.access_token);
        localStorage.setItem('company_user_refresh_token', profileData.refresh_token);
        await dispatch(companyGetFullProfile())
      dispatch(
        companyProfileActions.logIn({
          companyLoggedIn: true,
        })
      );
    } catch (error) {
    }
  };
};


export const companySignUp = (email, password, name, no_employees, phone, man_name) => {
    return async (dispatch) => {
      const postData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/account/company/`,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password:password, company_phone:phone, 
                company_manager_name:man_name, company_name:name, no_of_employees:no_employees, })
        }
        );
  
        if (!response.ok) {
          throw new Error('Could not fetch  data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const profileData = await postData();
        dispatch(
            companyProfileActions.signUp({
                companyLoggedIn: true,
                companyProfileDetails: {},
              })
        );
      } catch (error) {
      }
    };
  };


export const companyLogOut = () => {
    return async (dispatch) => {
      try {
        localStorage.clear();
        dispatch(
            companyProfileActions.logOut({})
        );
      } catch (error) {
      }
    };
  };


  export const companyGetFullProfile = () => {
    const companyId = localStorage.getItem('company_user_id')
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyProfileActions.getDataBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/account/company/${companyId}/details`,{ 
              method: 'get', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not fetch  data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const profileData = await fetchData();
        dispatch(
          companyProfileActions.companyFullProfile({
            companyProfileDetails: profileData,
          })
        );
      } catch (error) {
        dispatch(companyProfileActions.getDataError({
          error: error.message
        }));
      }
    };
  };


  export const autoSignIn = () => {
    const companyId = localStorage.getItem('company_user_id')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/account/company/refresh_access_token`,{ 
              method: 'get', 
              headers: new Headers({
                "id":companyId,
                "refresh-token":refresh_t
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not fetch  data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const profileData = await fetchData();
        localStorage.setItem('company_user_id', profileData.id);
        localStorage.setItem('company_user_email', profileData.email);
        localStorage.setItem('company_user_access_token', profileData.access_token);
        localStorage.setItem('company_user_refresh_token', profileData.refresh_token);
        await dispatch(companyGetFullProfile())
        dispatch(
          companyProfileActions.autoSignIn()
        );
      } catch (error) {
      }
    };
  };


  export const updateCompanyProfile = (email, compName) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    const companyId = localStorage.getItem('company_user_id')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/account/company/${companyId}/details`,{ 
              method: 'put', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ email: email,company_name:compName })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not update company profile!');
        }
  
  
        return true;
      };
  
      try {
        const updatedProfile = await fetchData();
          dispatch(companyGetFullProfile())
      } catch (error) {
      }
    };
  };


  export const subscribeAccount = (company_id, paymentMethod) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/payment/company/${company_id}/subscribe`,{ 
              method: 'post', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ payment_method_id: paymentMethod.id})
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not subscribe account!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const subscription = await fetchData();

      } catch (error) {
        console.log(error)
      }
    };
  };



  export const getSubscriptions = (company_id) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      dispatch(companyProfileActions.getDataBegin());
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/payment/company/${company_id}/subscribe`,{ 
              method: 'get', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          return null
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const subscription = await fetchData();
        await dispatch(companyProfileActions.companyGetSubscription({
          subscriptionDetails: subscription
          })
        );

      } catch (error) {
        console.log(error)
      }
    };
  };

  export const getDefaultPayment = (company_id) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/payment/company/${company_id}/card`,{ 
              method: 'get', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not subscribe account!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        const data = await fetchData();
        await dispatch(companyProfileActions.companyGetPayment({
          paymentDetails: data
          })
        );

      } catch (error) {
        console.log(error)
      }
    };
  };


  export const updateDefaultPayment = (company_id, paymentMethodId) => {
    const access_t = localStorage.getItem('company_user_access_token')
    const refresh_t = localStorage.getItem('company_user_refresh_token')
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/payment/company/${company_id}/card`,{ 
              method: 'put', 
              headers: new Headers({
                "access-token":access_t,
                "refresh-token":refresh_t,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({ payment_method_id: paymentMethodId})
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not subscribe account!');
        }

        const data = await response.json();
  
  
        return data;
      };
  
      try {
        await fetchData();
        await dispatch(getDefaultPayment(company_id))

      } catch (error) {
        console.log(error)
      }
    };
  };
