import { Fragment } from "react"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import classes from './Tile.module.css'
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';


const ProfileContactTile = (props) => {

    const navigate = useNavigate();

    return(
        <Fragment>
            <Card className={classes.profileContactCard}>
                <CardContent>
                <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                        <div className={classes.profileContactTitle} color="text.secondary" gutterBottom>
                            {props.name}'s verified contacts
                        </div>
                        <div className={classes.profileContactDetails} color="text.secondary" gutterBottom>
                            <EmailIcon /> 
                            <div>{props.email}</div>
                        </div>
                        <div className={classes.profileContactDetails} color="text.secondary" gutterBottom>
                            <PhoneIphoneIcon />
                            <div>{props.phone}</div>
                        </div>
                </Box>
                </CardContent>
            </Card>
        </Fragment>

    )
}

export default ProfileContactTile;