import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Fragment } from 'react';
import classes from './Listing.module.css';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


const ContractCard = (props)=>{
    const navigate = useNavigate();
    const created_date = new Date(props.created_at)


    const navToListing = ()=>{
        navigate(`/company/contracts/${props.id}`)
      }

    return (
        <Fragment>
            <img style={{cursor:'pointer', width:'3em', height:'3em', border:'1px solid lightgray', borderRadius: '5px'}} src={props.profile_pic} alt="Logo" onClick={navToListing} />
            <Box sx={{marginLeft:'1em'}}>
            <Link className={classes.listingTitle} to={`/company/contract/${props.id}`}>{props.first_name} {props.last_name}</Link>
                {/* <div className={classes.listingCompany}>{props.last_name}</div> */}
            </Box>
        </Fragment>
        
    )
}

export default ContractCard