import React, { Fragment } from 'react';


const ProfileReviews = ({ reviews, name, classes }) => {

    return (
        <Fragment>
            {reviews && reviews.length>0&&
                    <div>
                        <div style={{width:'100%', border:'0', borderTop:'1px solid #dddddd', marginTop:'2em', marginBottom:'2em'}}></div>

                        <div className={classes.publicProfileReviewTitle}>What others are saying about {name}</div>
                        
                        
                        {
                            reviews.map((review)=>{
                                return (
                                    <div className={classes.publicProfileReview}>
                                        <img style={{cursor:'pointer', width:'3em', height:'3em', border:'1px solid lightgray', borderRadius: '5px'}} src={review.review_source_pic} alt="Logo"/>
                                        <div>
                                            {review.review}
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>}
        </Fragment>
    )
}

export default ProfileReviews;