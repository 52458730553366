import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import { Fragment } from 'react';
import classes from '../Listing/Listing.module.css'
import axios from 'axios';
import { useEffect } from 'react';

const RichTextEditor = ({ description, changeDescription, title, company_name }) => {

  
  var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
  const LOCAL_DOMAIN = "http://0.0.0.0:8000"
  const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

  if (process.env.REACT_APP_ENV==='local'){
    BASE_DOMAIN=LOCAL_DOMAIN
  }
  else if (process.env.REACT_APP_ENV==='test'){
    BASE_DOMAIN=TEST_DOMAIN
  }

  const [content, setContent] = useState(description);

  const handleChange = (value) => {
    setContent(value);
    changeDescription(value);
  };



  const generateDescription = async () => {
    // Replace with your OpenAI API key
    


    try {
      const prompt = `Generate a job description for a role titled ${title}. The company hiring is ${company_name}. 
      Just show the job summary, responsibilities, and requirements. Nothing else, not the job title either`;
    const messages = [
      {"role": "system", "content": "You are an expert in career coaching."},
      {"role": "user", "content": prompt},
  ]

      const response = await axios.post(
        `${BASE_DOMAIN}/ai/openai/prompt`,
        {
          messages: messages
        }
      );
      const generatedText = response.data.content.choices[0].message.content;
      
      const lines = formatOpenAIResponse(generatedText);
      setContent(lines)
      return lines;
    } catch (error) {
      console.error('Error generating careers:', error);
      return [];
    }
  };

  const formatOpenAIResponse = (responseText) => {
    // Format the response as needed, e.g., adding paragraphs or other HTML elements
    return `<p>${responseText.replace(/\n/g, '</p><p>')}</p>`;
  };

  

  return (
    <Fragment>
    <div style={{height:'400px'}}>
      <ReactQuill style={{height:'80%'}} value={content}  onChange={handleChange} />
      
    </div>
    <button className={classes.updateListingButton} onClick={generateDescription}>Generate job description</button>
    </Fragment>
  );
};

export default RichTextEditor;