import { createSlice } from '@reduxjs/toolkit';

const companyProfileSlice = createSlice({
  name: 'companyProfile',
  initialState: {
    companyProfileDetails:{},
    companyPaymentDetails:{},
    companySubscriptionDetails:null,
    companyLoggedIn: false,
    companySignedUp: false,
    loading: false,
    error: null,
  },
  reducers: {
    logIn(state, action) {
        state.companyLoggedIn = action.payload.companyLoggedIn
    },
    signUp(state, action) {
        state.companySignedUp = action.payload.companySignedUp
    },
    logOut(state, action) {
        state.companyProfileDetails = {}
        state.companyLoggedIn = false
    },
    companyFullProfile(state, action) {
        state.companyProfileDetails = action.payload.companyProfileDetails
        state.loading = false
    },
    companyGetPayment(state, action) {
      state.companyPaymentDetails = action.payload.paymentDetails
      state.loading = false
    },
    companyGetSubscription(state, action) {
      state.companySubscriptionDetails = action.payload.subscriptionDetails
      state.loading = false
    },
    autoSignIn(state, action) {
        state.companyLoggedIn = true
    },
    getDataBegin(state, action) {
        state.loading = true
    },
    getProfileError(state, action) {
        state.loading = false
        state.error = action.payload.error
    },
    getDataError(state, action) {
      state.error = action.payload.error
  },
  },
});

export const companyProfileActions = companyProfileSlice.actions;

export default companyProfileSlice;
