import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import classes from './MainHeader.module.css';
import HyrewizLogo from '../../logo.png';
import SignUpButton from './SignUpButton';
import {useNavigate} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import {useEffect} from 'react';
import { companyGetFullProfile } from '../../store/companyProfile-actions';
import CompanyMobileMenu from './CompanyMobileMenu';



import { useSelector, useDispatch } from 'react-redux';
import { companyLogOut } from '../../store/companyProfile-actions';

const pages = []
// const pages = ['Products', 'Pricing', 'Companies'];
const profileLinks = ['Profile', 'Sign Out']


const CompanyMainHeader = ()=> {

  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const profile = useSelector((state) => state.companyProfile);

  useEffect(() => {
    const fetchData = async () => {
      if(localStorage.getItem('company_user_id')){
        await dispatch(companyGetFullProfile());
      }
        
    };
    fetchData();
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();

  const navHomePage = ()=>{
    if (profile.companyLoggedIn){
      navigate('/company')
    }
    else{
      navigate('/')
    }
  }

  const handleLogOut = (event) =>{
    event.preventDefault()
    navigate('/company')
    dispatch(companyLogOut())
  }

  const navProfile = (event) =>{
    event.preventDefault()
    handleCloseNavMenu()
    navigate('/company/profile')
  }

  const navPayment = (event) =>{
    event.preventDefault()
    handleCloseNavMenu()
    navigate('/company/payment')
  }

  const navAccount = (event) =>{
    event.preventDefault()
    handleCloseNavMenu()
    navigate('/company/account')
  }



  return (
    <AppBar position="static" sx={{ bgcolor: "#F3F2EF", boxShadow: "none", borderBottom: "2px solid #ededed;" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
              width:'11em',
              height:'4em',
              marginLeft: '2em',
              marginRight: '4em',
              fontSize: '12px',
            }} className={classes.logo}>
            <img style={{cursor:'pointer'}} onClick={navHomePage} src={HyrewizLogo} alt="Logo" />
            </Box>
          
          <Box sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              maxWidth: '10em'
            }} className={classes.logo}>
            <img style={{cursor:'pointer'}} onClick={navHomePage} src={HyrewizLogo} alt="Logo" />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
          {!profile.companyLoggedIn&&<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: '#000000', display: 'block' }}
                style={{color: '#000000', padding:'6px'}}
              >
                {page}
              </Button>
            ))}
            </Box>}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
          <ul>
          {/* {!(profile.companyLoggedIn)&&<li>
            <SignUpButton linkTo="/company/signup"/>
          </li>} */}
        </ul>
            
          </Box>
          {!(profile.companyLoggedIn)&&<Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, marginLeft: 'auto', marginRight: '0' }} className={classes.mobileMenuSignOut}>
            {/* <SignUpButton linkTo="/company/signup"/> */}
            <CompanyMobileMenu />
          </Box>}
          {profile.companyLoggedIn&&<Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' }, marginLeft: '2em' }}>
          <Tooltip title="Open settings">
              <IconButton onClick={handleOpenNavMenu} sx={{ p: 0 }}>
                <Avatar alt={profile.companyProfileDetails.company_name} src={profile.companyProfileDetails.profile_pic} />
              </IconButton>
            </Tooltip>
          
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'block' }, color:'#000000'
              }}
            >
              <MenuItem onClick={navProfile}>Profile</MenuItem>
              <MenuItem onClick={navPayment}>Payment</MenuItem>
              <MenuItem onClick={navAccount}>Account</MenuItem>
              <MenuItem onClick={handleLogOut}>Sign out</MenuItem>
            </Menu>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default CompanyMainHeader;
