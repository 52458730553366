import { recruiterProfileActions } from './recruiterProfile-slice';

var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
const LOCAL_DOMAIN = "http://0.0.0.0:8000"
const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

if (process.env.REACT_APP_ENV==='local'){
  BASE_DOMAIN=LOCAL_DOMAIN
}
else if (process.env.REACT_APP_ENV==='test'){
  BASE_DOMAIN=TEST_DOMAIN
}
  
  export const recruiterLogIn = (email, password) => {
    return async (dispatch) => {
      const fetchData = async () => {
        const response = await fetch(
            `${BASE_DOMAIN}/account/recruiter/`,{ 
              method: 'get', 
              headers: new Headers({
                "email":email,
                "password":password
              })
            }
        );
  
        if (!response.ok) {
          throw new Error('Could not fetch  data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const profileData = await fetchData();
        localStorage.clear();
        localStorage.setItem('user_id', profileData.id);
        localStorage.setItem('user_email', profileData.email);
        localStorage.setItem('user_access_token', profileData.access_token);
        localStorage.setItem('user_refresh_token', profileData.refresh_token);
        await dispatch(recruiterGetFullProfile())
        await dispatch(
        recruiterProfileActions.logIn({
            recruiterLoggedIn: true,
          })
        );
      } catch (error) {
      }
    };
  };
  
  
  export const recruiterSignUp = (email, password, firstName, lastName, phone) => {
      return async (dispatch) => {
        const postData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/account/recruiter/`,{
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email: email, password:password, first_name:firstName, last_name:lastName, recruiter_phone:phone })
          }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const profileData = await postData();
          dispatch(
            recruiterProfileActions.signUp({
                  recruiterLoggedIn: true,
                  recruiterProfileDetails: {},
                })
          );
        } catch (error) {
        }
      };
    };
  
  
  export const recruiterLogOut = () => {
      return async (dispatch) => {
        try {
          localStorage.clear();
          dispatch(
            recruiterProfileActions.logOut({})
          );
        } catch (error) {
        }
      };
    };


    
    export const recruiterGetFullProfile = () => {
      const recruiterId = localStorage.getItem('user_id')
      const access_t = localStorage.getItem('user_access_token')
      const refresh_t = localStorage.getItem('user_refresh_token')
      return async (dispatch) => {
        dispatch(recruiterProfileActions.getDataBegin());
        const fetchData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/account/recruiter/${recruiterId}/details`,{ 
                method: 'get', 
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t
                })
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const profileData = await fetchData();
          dispatch(
            recruiterProfileActions.recruiterFullProfile({
              recruiterProfileDetails: profileData,
            })
          );
        } catch (error) {
          dispatch(recruiterProfileActions.getDataError(
            {error:error}
          ))
        }
      };
    };


    export const autoSignIn = () => {
      const recruiterId = localStorage.getItem('user_id')
      const refresh_t = localStorage.getItem('user_refresh_token')
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
              `${BASE_DOMAIN}/account/recruiter/refresh_access_token`,{ 
                method: 'get', 
                headers: new Headers({
                  "id":recruiterId,
                  "refresh-token":refresh_t
                })
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const profileData = await fetchData();
          localStorage.setItem('user_id', profileData.id);
          localStorage.setItem('user_email', profileData.email);
          localStorage.setItem('user_access_token', profileData.access_token);
          localStorage.setItem('user_refresh_token', profileData.refresh_token);
          await dispatch(recruiterGetFullProfile())
          await dispatch(recruiterProfileActions.autoSignIn());
        } catch (error) {
        }
      };
    };


    export const updateRecruiterProfile = (email, firstName, lastName, phone, linkedinUrl, personalUrl, twitterUrl, resumeUrl, city, state, country, description) => {
      const access_t = localStorage.getItem('user_access_token')
      const refresh_t = localStorage.getItem('user_refresh_token')
      const recruiterId = localStorage.getItem('user_id')
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
              `${BASE_DOMAIN}/account/recruiter/${recruiterId}/details`,{ 
                method: 'put', 
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ 
                  email: email, 
                  first_name:firstName, 
                  last_name:lastName, 
                  recruiter_phone:phone, 
                  linkedin_url:linkedinUrl, 
                  personal_url:personalUrl, 
                  twitter_url:twitterUrl, 
                  resume_url:resumeUrl,
                  city:city,
                  state:state,
                  country:country,
                  description:description 
                })
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not update recruiter profile!');
          }
    
    
          return true;
        };
    
        try {
          const updatedProfile = await fetchData();
            dispatch(recruiterGetFullProfile())
        } catch (error) {
        }
      };
    };


    export const recruiterPublicProfile = (recruiterId) => {
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
            `${BASE_DOMAIN}/account/recruiter/${recruiterId}/public`,{ 
                method: 'get', 
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not fetch  data!');
          }
    
          const data = await response.json();
    
          return data;
        };
    
        try {
          const profileData = await fetchData();
          dispatch(
            recruiterProfileActions.recruiterPublicProfile({
              recruiterPublicDetails: profileData,
            })
          );
        } catch (error) {
        }
      };
    };

    export const getDefaultPayment = (recruiter_id) => {
      const access_t = localStorage.getItem('user_access_token')
      const refresh_t = localStorage.getItem('user_refresh_token')
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
              `${BASE_DOMAIN}/payment/recruiter/${recruiter_id}/card`,{ 
                method: 'get', 
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t,
                  'Content-Type': 'application/json'
                })
              }
          );
    
          if (!response.ok) {
            return null
          }
  
          const data = await response.json();
    
    
          return data;
        };
    
        try {
          const data = await fetchData();
          await dispatch(recruiterProfileActions.recruiterGetPayment({
            paymentDetails: data
            })
          );
  
        } catch (error) {
          console.log(error)
        }
      };
    };
  
  
    export const updateDefaultPayment = (recruiter_id, paymentMethodId) => {
      const access_t = localStorage.getItem('user_access_token')
      const refresh_t = localStorage.getItem('user_refresh_token')
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
              `${BASE_DOMAIN}/payment/recruiter/${recruiter_id}/card`,{ 
                method: 'put', 
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ payment_method_id: paymentMethodId})
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not subscribe account!');
          }
  
          const data = await response.json();
    
    
          return data;
        };
    
        try {
          await fetchData();
          await dispatch(getDefaultPayment(recruiter_id))
  
        } catch (error) {
          console.log(error)
        }
      };
    };


    export const subscribeAccount = (recruiter_id, paymentMethod) => {
      const access_t = localStorage.getItem('user_access_token')
      const refresh_t = localStorage.getItem('user_refresh_token')
      return async (dispatch) => {
        const fetchData = async () => {
          const response = await fetch(
              `${BASE_DOMAIN}/payment/recruiter/${recruiter_id}/subscribe`,{ 
                method: 'post', 
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ payment_method_id: paymentMethod.id})
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not subscribe account!');
          }
  
          const data = await response.json();
    
    
          return data;
        };
    
        try {
          const subscription = await fetchData();
  
        } catch (error) {
          console.log(error)
        }
      };
    };


    export const getSubscriptions = (recruiter_id) => {
      const access_t = localStorage.getItem('user_access_token')
      const refresh_t = localStorage.getItem('user_refresh_token')
      return async (dispatch) => {
        dispatch(recruiterProfileActions.getDataBegin());
        const fetchData = async () => {
          const response = await fetch(
              `${BASE_DOMAIN}/payment/recruiter/${recruiter_id}/subscribe`,{ 
                method: 'get', 
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t,
                  'Content-Type': 'application/json'
                })
              }
          );
    
          if (!response.ok) {
            return null
          }
  
          const data = await response.json();
    
    
          return data;
        };
    
        try {
          const subscription = await fetchData();
          await dispatch(recruiterProfileActions.recruiterGetSubscription({
            subscriptionDetails: subscription
            })
          );
  
        } catch (error) {
          console.log(error)
        }
      };
    };


    export const getConnectAccount = (recruiter_id) => {
      const access_t = localStorage.getItem('user_access_token')
      const refresh_t = localStorage.getItem('user_refresh_token')
      return async (dispatch) => {
        dispatch(recruiterProfileActions.getDataBegin());
        const fetchData = async () => {
          const response = await fetch(
              `${BASE_DOMAIN}/payment/recruiter/${recruiter_id}/stripe/connect`,{ 
                method: 'get', 
                headers: new Headers({
                  "access-token":access_t,
                  "refresh-token":refresh_t,
                  'Content-Type': 'application/json'
                })
              }
          );
    
          if (!response.ok) {
            throw new Error('Could not subscribe account!');
          }
  
          const data = await response.json();
    
    
          return data;
        };
    
        try {
          const connect_account = await fetchData();
          await dispatch(recruiterProfileActions.getConnectAccount({
            connectAccount: connect_account
            })
          );
  
        } catch (error) {
          console.log(error)
        }
      };
    };