import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modalMessage:null,
  },
  reducers: {
    setModalMessage(state, action) {
        state.modalMessage = action.payload.message
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice;
