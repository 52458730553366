import { createSlice } from '@reduxjs/toolkit';

const recruiterListingSlice = createSlice({
  name: 'publicListings',
  initialState: {
    publicListingDetails:{},
    contractDetails:{},
    contractPipelines:[],
    recruiterListingsList:[],
    allListings:null,
    loading: false,
    error: null,
  },
  reducers: {
    getDataBegin(state, action) {
      state.loading = true
  },
    getPublicListingDetails(state, action) {
        state.publicListingDetails = action.payload.listingDetails
        state.loading = false
  },
    getDataError(state, action) {
      state.error = action.payload.error
      state.publicListingDetails = null
  },
    getContractDetails(state, action) {
      state.contractDetails = action.payload.contractDetails
  },
    getContractPipelines(state, action) {
      state.contractPipelines = action.payload.contractPipelines
  },
    getAllListings(state, action) {
      state.allListings = action.payload.listings
      state.loading = false
  },
    recruiterGetListingsList(state, action) {
      state.recruiterListingsList = action.payload.listingsList
      state.loading = false
  },
    startGetListing(state, action) {
    state.contractDetails = {}
    state.contractPipelines = []
    state.publicListingDetails = {}
  },

  },
});

export const recruiterListingActions = recruiterListingSlice.actions;

export default recruiterListingSlice;
