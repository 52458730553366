import {useParams, useNavigate, Link} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { getContractDetails } from "../../store/companyListings-action";
import { Fragment } from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import classes from './Listing.module.css'
import CandidateCard from "./CandidateCard";
import ContractCard from "./ContractCard";




const ListingContract = ()=>{
    const navigate = useNavigate()
    const {contractId} = useParams()
    const dispatch = useDispatch()

    const [contractDetails, setContractDetails] = useState({})

    const contractDetailsRes = useSelector(state=>(state.companyListings.contractDetails))

    useEffect(()=>{
        const fetchData = async ()=>{
            await dispatch(getContractDetails(contractId))
        }
        fetchData()
    },[dispatch, contractId])

    useEffect(()=>{
        setContractDetails(contractDetailsRes)
    },[contractDetailsRes])

    const navToPipeline = (id)=>{
        navigate(`/company/pipeline/${id}`)
    }
    


    return (
        <Fragment>
        {contractDetailsRes&&
        <Box style={{display:"inline-flex", width:"100%", marginTop:"3em"}}>

        <Box className={classes.listingsContainer}>
        
            {contractDetails.id&&
            <div className={classes.contractDetailsCard}>
                <div className={classes.cardRecruiterDetails}>
                    <div className={classes.cardRecruiterTitle}>
                        <img style={{cursor:'pointer', width:'3em', height:'3em', border:'1px solid lightgray', borderRadius: '5px'}} src={contractDetails.picture} alt="Hyrewiz Logo"/>
                        <div className={classes.cardRecruiterName}>
                            <div className={classes.jobDescription}>{contractDetails.first_name}</div>
                            <div className={classes.jobDescription}>{contractDetails.last_name}</div>
                        </div>
                        
                    </div>
                    <div className={classes.cardRecruiterContact}>
                        <div className={classes.jobDescription}>{contractDetails.phone}</div>
                        <div className={classes.jobDescription}>{contractDetails.rec_email}</div>
                    </div>
                </div>
                
                <div className={classes.cardPipelines}>
                {
                    contractDetails.candidates.map((candidate)=>{
                        return (
                            <Box key={candidate.id} className={classes.pipelineCard} onClick={() => navToPipeline(candidate.id)}>
                                <CandidateCard key={candidate.id} {...candidate} />
                            </Box>
                        )
                    })
                }
                </div>
            </div>
            }
        
        </Box>
        
        </Box>
        }
        {!contractDetailsRes&&
        <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="rectangular" width="100%" height={60} />
            
            <Skeleton variant="rounded" width="100%" height={60}  />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rounded" width="100%" height={60}  />
        </Stack>
}
        </Fragment>
        )

}

export default ListingContract