import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Auth.module.css';
import { useState } from 'react';
import Divider from '@mui/material/Divider';

import { Fragment } from 'react';


import { useSelector, useDispatch } from 'react-redux';
import { recruiterSignUp } from '../../store/recruiterProfile-actions';

import {useNavigate} from "react-router-dom";




const RecruiterSignUp = () =>{

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const profile = useSelector((state) => state.recruiterProfile);

    const [recruiterEmail, setRecruiterEmail] = useState('')
    const [recruiterPassword, setRecruiterPassword] = useState('')
    const [recruiterFirstName, setRecruiterFirstName] = useState('')
    const [recruiterLastName, setRecruiterLastName] = useState('')
    const [recruiterPhone, setRecruiterPhone] = useState('')


    const handleCreateRecruiter = (event) =>{
        event.preventDefault()
        dispatch(recruiterSignUp(recruiterEmail,recruiterPassword, recruiterFirstName, recruiterLastName, recruiterPhone))
        setRecruiterEmail('')
        setRecruiterPassword('')
        setRecruiterFirstName('')
        setRecruiterLastName('')
        setRecruiterPhone('')
        navigate('/user')
    }

    const handleRecruiterEmailChange = (event) =>{
        event.preventDefault()
        setRecruiterEmail(event.target.value)
    }

    const handleRecruiterPasswordChange = (event) =>{
        event.preventDefault()
        setRecruiterPassword(event.target.value)
    }

    const handleRecruiterFirstNameChange = (event) =>{
      event.preventDefault()
      setRecruiterFirstName(event.target.value)
    }

    const handleRecruiterLastNameChange = (event) =>{
      event.preventDefault()
      setRecruiterLastName(event.target.value)
    }

    const handleRecruiterPhoneChange = (event) =>{
      event.preventDefault()
      setRecruiterPhone(event.target.value)
    }

    return (
        <Fragment>
        <Container> 
    <Box className="signUpForm"
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        marginLeft:"auto",
        marginRight:'auto',
        marginTop: "4em",
        marginBottom: "4em",
        width: "fit-content"
      }}
      noValidate
      autoComplete="off"
    >
        <TextField
          required
          id="outlined-required"
          label="First name"
          value={recruiterFirstName}
          onChange={handleRecruiterFirstNameChange}
        />
        <TextField
          required
          id="outlined-required"
          label="Last name"
          value={recruiterLastName}
          onChange={handleRecruiterLastNameChange}
        />
        <TextField
          required
          id="outlined-required"
          label="Work email"
          value={recruiterEmail}
          onChange={handleRecruiterEmailChange}
        />
        <TextField
          required
          id="outlined-password-input"
          label="Set password"
          type="password"
          autoComplete="current-password"
          value={recruiterPassword}
          onChange={handleRecruiterPasswordChange}
        />
        <TextField
          id="outlined-number-required"
          label="Phone number"
          value={recruiterPhone}
          onChange={handleRecruiterPhoneChange}
        />

<button onClick={handleCreateRecruiter} className={classes.button}>
      <span>Sign Up</span>
    </button>
                
    </Box>

        </Container>
        </Fragment>
    )
}

export default RecruiterSignUp