import {useParams, useNavigate, Link} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { getListingDetails, publishListing, unPublishListing, approveListingAccess, getListingContracts, getAllListingContracts } from "../../store/companyListings-action";
import { Fragment } from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import classes from './Listing.module.css'
import ListingCard from "./ListingCard";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PaymentsIcon from '@mui/icons-material/Payments';
import SettingsIcon from '@mui/icons-material/Settings';



const ListingDetail = ()=>{
    const navigate = useNavigate()
    const {listingId} = useParams()
    const dispatch = useDispatch()


    const listingDetails = useSelector(state=>(state.companyListings.listingDetails))
    const listingPendingContracts = useSelector(state=>(state.companyListings.companyPendingListingContracts))
    const loading = useSelector((state) => state.companyListings.loading);
    const error = useSelector((state) => state.companyListings.error);


    

    useEffect(()=>{
        const fetchData = async ()=>{
            await dispatch(getListingDetails(listingId));
        }
        fetchData()
    },[])
    


    const handleEditListing = ()=>{
        navigate(`/company/listing/edit/${listingId}`)
    }

    const handlePublishListing = ()=>{
        dispatch(publishListing(listingId))
    }

    const handleUnPublishListing = ()=>{
        dispatch(unPublishListing(listingId))
    }

    const handleApprove = async (event)=>{
        await dispatch(approveListingAccess(event.currentTarget.id, "approve"))
        dispatch(getListingDetails(listingId))
        dispatch(getListingContracts(listingId))
    }

    const handleReject = async (event)=>{
        await dispatch(approveListingAccess(event.currentTarget.id, "decline"))
        dispatch(getListingDetails(listingId))
    }

    const handleRecruiterClick = (event)=>{
        navigate(`/user/${event.currentTarget.id}`)
    }



    if (loading) {
        return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="rectangular" width="100%" height={60} />
        
        <Skeleton variant="rounded" width="100%" height={60}  />
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60}  />
    </Stack>
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      if (!listingDetails) {
        return null;
      }


    return (
        <Fragment>

        <Box className={classes.listingsContainer}>
            <Box className={classes.listingNavContainer}>
            
            <div className={classes.navList}>
                <FeaturedPlayListIcon onClick={()=>{navigate(`/company/listings/${listingId}/contracts`)}}/>
                <Link className={classes.listingTitle} to={`/company/listings/${listingId}/contracts`}>Contracts</Link>
            </div>
            <div className={classes.navList}>
                <ListAltIcon onClick={()=>{navigate(`/company/listings/${listingId}/candidates`)}}/>
                <Link className={classes.listingTitle} to={`/company/listings/${listingId}/candidates`}>Candidates</Link>
            </div>
            <div className={classes.navList}>
                <PersonAddIcon onClick={()=>{navigate(`/company/listings/`)}}/>
                <Link className={classes.listingTitle} to={`/company/listings/${listingId}/requests`}>Requests</Link>
            </div>
            <div className={classes.navList}>
                <PaymentsIcon onClick={()=>{navigate(`/company/listings/`)}}/>
                <Link className={classes.listingTitle} to={`/company/listings/${listingId}/payment`}>Payment</Link>
            </div>
            <div className={classes.navList}>
                <SettingsIcon onClick={()=>{navigate(`/company/listings/`)}}/>
                <Link className={classes.listingTitle} to={`/company/listings/`}>Listing settings</Link>
            </div>

            </Box>

        <Box className={classes.listingDetailsContainer}>
        <Box className={classes.listingCardDetails}><ListingCard {...listingDetails}/></Box>
            {listingDetails.description&&<div><div className={classes.jobDescriptionTitle}>Job description</div>
            <div className={classes.jobDescription}>
            <div dangerouslySetInnerHTML={{ __html: listingDetails.description }}/>
                </div></div>}
            {listingDetails.qualifications!=null&&<div><div className={classes.jobSubTitle}>Qualifications</div>
            <div className={classes.jobDescription}>{listingDetails.qualifications.map((skill, index)=>{
                return(<li key={index}>{skill}</li>)
            })}</div></div>}
            {listingDetails.job_links!=null&&<div><div className={classes.jobSubTitle}>Job related links</div>
            <div className={classes.jobDescription}>{listingDetails.job_links.map((link, index)=>{
                return(<div key={index}><a href={link}>{link}</a></div>)
            })}</div></div>}
            {listingDetails.salary_range!=null&&<div><div className={classes.jobSubTitle}>Salary range</div>
            <div className={classes.jobDescription}>${listingDetails.salary_range.min_val} - ${listingDetails.salary_range.max_val}</div></div>}
            <div style={{display:"inline-flex"}}>{listingDetails.workplace_type!=null&&listingDetails.workplace_type.length>0&&<div><div className={classes.jobSubTitle}><div className={classes.detailsSpans}><span>{listingDetails.workplace_type}</span></div></div></div>}
            {listingDetails.employment_type!=null&&listingDetails.employment_type.length>0&&<div><div className={classes.jobSubTitle}><div className={classes.detailsSpans}><span>{listingDetails.employment_type}</span></div></div></div>}
            </div>
            <div className={classes.listingButtonGroup}>
            
            <button className={classes.button} onClick={handleEditListing}>
            <span>Edit listing</span>
        </button>
        {listingDetails.status==='pending'&&<button className={classes.button} onClick={handlePublishListing}>
            <span>Publish listing</span>
        </button>}
        {listingDetails.status==='approved'&&<button className={classes.button} onClick={handleUnPublishListing}>
            <span>Unpublish listing</span>
        </button>}
        </div>
        {listingPendingContracts.length != 0&&<div><div className={classes.jobSubTitle}>Requests</div>
            <div className={classes.jobDescription}>{listingPendingContracts.map((contract, index)=>{
                return(<div key={index}><span style={{cursor:"pointer"}} id={contract.id} onClick={handleRecruiterClick}>{contract.first_name} {contract.last_name}</span>
                <button className={classes.addRespButton} id={contract.id} onClick={handleApprove}>Approve</button>
                <button className={classes.addRespButton} id={contract.id} onClick={handleReject}>Reject</button>
                </div>)
            })}</div></div>}
        
        </Box>
        </Box>
        </Fragment>
        )

}

export default ListingDetail