import {useParams, useNavigate} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { Fragment } from "react";
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import classes from './Listing.module.css'
import PublicListingCard from "./PublicListingCard";
import CandidateCard from "./CandidateCard";
import ModalMessage from "../UI/ModalMessage"
import { getPublicListingDetails, requestAccessListing, getContractDetails, addCandidateListing, getContractPipelines } from "../../store/recruiterListings-action";


const ListingPublicView = ()=>{
    const navigate = useNavigate()
    const {listingId} = useParams()
    const dispatch = useDispatch()

    const [candidateEmail, setCanidateEmail] = useState("")

    const listingDetails = useSelector(state=>(state.publicListings.publicListingDetails))
    const listingContractDetails = useSelector(state=>(state.publicListings.contractDetails))
    const contractPipelines = useSelector(state=>(state.publicListings.contractPipelines))
    const loading = useSelector((state) => state.publicListings.loading);
    const error = useSelector((state) => state.publicListings.error);
    const modal = useSelector(state=>(state.modal.modalMessage))

    useEffect(()=>{
        const fetchDetails = async ()=>{
            await dispatch(getPublicListingDetails(listingId))
        }
        fetchDetails()
        
    }, [dispatch, listingId])

    

    const handleRequestListingAccess = async (event) =>{
        event.preventDefault()
        await dispatch(requestAccessListing(listingId))
        await dispatch(getContractDetails(listingId))


    }

    const handleCandidateEmailChange = (event) =>{
        setCanidateEmail(event.target.value)
    }

    const handleAddCandidate = async (event) =>{
        event.preventDefault()
        await dispatch(addCandidateListing(listingContractDetails.id, candidateEmail))
        setCanidateEmail("")
    }


    if (loading) {
      return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
      <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
      <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
      <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
      <Skeleton variant="rectangular" width="100%" height={60} />
      
      <Skeleton variant="rounded" width="100%" height={60}  />
      <Skeleton variant="rectangular" width="100%" height={60} />
      <Skeleton variant="rounded" width="100%" height={60}  />
  </Stack>
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!listingDetails) {
      return null;
    }

    return (
        <Fragment>
        {listingDetails.title&&
        <Box style={{"width":"80%", "marginLeft":"auto", "marginRight":"auto"}}>

        <Box className={classes.listingDetailsContainer}>
        <Box className={classes.listingCardDetails}><PublicListingCard {...listingDetails}/></Box>
            {listingDetails.description&&<div><div className={classes.jobDescriptionTitle}>Job description</div>
            <div className={classes.jobDescription}>{listingDetails.description}</div></div>}
            {listingDetails.qualifications!=null&&<div><div className={classes.jobSubTitle}>Qualifications</div>
            <div className={classes.jobDescription}>{listingDetails.qualifications.map((skill, index)=>{
                return(<li key={index}>{skill}</li>)
            })}</div></div>}
            {listingDetails.job_links!=null&&<div><div className={classes.jobSubTitle}>Job related links</div>
            <div className={classes.jobDescription}>{listingDetails.job_links.map((link, index)=>{
                return(<div key={index}><a href={link}>{link}</a></div>)
            })}</div></div>}
            {listingDetails.salary_range!=null&&<div><div className={classes.jobSubTitle}>Salary range</div>
            <div className={classes.jobDescription}>${listingDetails.salary_range.min_val} - ${listingDetails.salary_range.max_val}</div></div>}
            <div style={{display:"inline-flex"}}>{listingDetails.workplace_type!=null&&listingDetails.workplace_type.length>0&&<div><div className={classes.jobSubTitle}><div className={classes.detailsSpans}><span>{listingDetails.workplace_type}</span></div></div></div>}
            {listingDetails.employment_type!=null&&listingDetails.employment_type.length>0&&<div><div className={classes.jobSubTitle}><div className={classes.detailsSpans}><span>{listingDetails.employment_type}</span></div></div></div>}
            </div>
            <div style={{display:"inline-flex"}}>
            {listingDetails.candidates_linked!=null&&Object.keys(listingDetails.candidates_linked).length>0&&<div><div className={classes.jobSubTitle}><div className={classes.detailsSpans}><span>Test</span></div></div></div>}
            </div>
            {contractPipelines.length>0&&<div className={classes.jobDescription} style={{width:'40%'}}>{contractPipelines.map((contract, index)=>{
                return (
                    <Box key={contract.id} className={classes.listingCard}>
                        <CandidateCard key={contract.id} {...contract} />
                    </Box>
                )
                // return(<li key={index}>{contract.first_name}  {contract.status}</li>)
            })}</div>}
            {Object.keys(listingContractDetails).length<1&&<button className={classes.updateListingButton} onClick={handleRequestListingAccess}>
                <span>Request access</span>
            </button>}
            {listingContractDetails.id&&listingContractDetails.status===0&&<div className={classes.jobSubTitle}>Request pending</div>}

            {listingContractDetails.id&&listingContractDetails.status===1&&
            <Box>
                <div className={classes.editNewListingContainer} style={{marginTop:'2em'}}><TextField
                    id="outlined-multiline-flexible"
                    label="Candidate email"
                    maxRows={4}
                    value={candidateEmail}
                    className={classes.addRespInput}
                    onChange={handleCandidateEmailChange}
                    />
                    <button className={classes.addRespButton} onClick={handleAddCandidate}>
                        <span>Add</span>
                    </button>
                    </div>
            </Box>

            }
        </Box>
        {modal&&<ModalMessage message={modal} />}   
        
        </Box>
        }
        </Fragment>
        )

}

export default ListingPublicView