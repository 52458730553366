
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import classes from './Recruiter.module.css'
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PublicIcon from '@mui/icons-material/Public';
import AccountTile from '../UI/AccountTile';


const RecruiterAccount = () => {

  return (
    <Box className={classes.accountContainer}>
        <div>
            <div><h1>Account</h1></div>
            <div>Manage your account settings and set e-mail preferences</div>
        </div>
        <Box className={classes.accountTiles}>
            <AccountTile page="/user/profile" title="Personal info" subTitle="Provide personal details and how we can reach you">
                <PermIdentityIcon className={classes.tileIcon}/>
            </AccountTile>
            <AccountTile page="/user/notification" title="Notifications" subTitle="Choose notification preferences and how you want to be contacted">
                <NotificationsNoneIcon className={classes.tileIcon}/>
            </AccountTile>
            <AccountTile page="/user/payout" title="Payments & payouts" subTitle="Review payments, payouts, coupons, and gift cards">
                <AccountBalanceIcon className={classes.tileIcon}/>
            </AccountTile>
            <AccountTile page="/user/subscription" title="Subscription" subTitle="Manage your hirewiz subscription">
                <CardMembershipIcon className={classes.tileIcon}/>
            </AccountTile>
            <AccountTile page="/user/public" title="Public profile" subTitle="View your public profile">
                <PublicIcon className={classes.tileIcon}/>
            </AccountTile>
            <AccountTile page="/user/listings/all" title="Listings" subTitle="View or manage listings you are linked to">
                <ListAltIcon className={classes.tileIcon}/>
            </AccountTile>
        
        </Box>
    </Box>
  );
}

export default RecruiterAccount;