import { Fragment } from "react"
import Box from '@mui/material/Box';
import classes from './Pipeline.module.css'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getListingPipeline, updatePipelineStatus, approvePipelineHire } from "../../store/companyListings-action";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import LinkedinLogo from '../../linkedin.png';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';



const Pipeline = () => {

    const {pipelineId} = useParams()
    const dispatch = useDispatch()

    const [salary, setSalary] = useState("")

    const pipeline = useSelector(state=>(state.companyListings.pipelineDetails))

    var progress = [
        {
          id: '1',
          label: 'Approved',
        },
        {
          id: '2',
          label: 'Phone call',
        },
        {
          id: '3',
          label: 'Interviewing',
        },
        {
          id: '5',
          label: 'Offer',
        },
      ];

    useEffect(()=>{
        const fetchDetails = async ()=>{
            await dispatch(getListingPipeline(pipelineId))
        }
        
        fetchDetails()
        
    }, [])

    const updatePipeline = async ()=>{
        await dispatch(updatePipelineStatus(pipelineId, pipeline.status_id))
    }

    const approveHire = async ()=>{
        await dispatch(approvePipelineHire(pipelineId, salary))
    }

    const handleSalaryChange = (e)=>{
      const value = e.target.value;
        if (value === "" || /^-?\d+(\.\d+)?$/.test(value)) {
          setSalary(value);
        }
    }


    return (
        <Fragment>
        {pipeline.id&&
        <Box className={classes.listingsContainer}>
                <div className={classes.cardRecruiterDetails}>
                    <div className={classes.cardPipelineDetails}>
                        <div className={classes.candidatePipelineCard}>
                            <div className={classes.cardRecruiterTitle}>
                              {pipeline.profile_pic&&

                                <img style={{cursor:'pointer', width:'3em', height:'3em', border:'1px solid lightgray', borderRadius: '5px'}} src={pipeline.profile_pic} alt="Logo"/>
                              }
                              {!pipeline.profile_pic&&

<Avatar>{pipeline.candidate_first_name[0]}{pipeline.candidate_last_name[0]}</Avatar>
}
                                <div className={classes.cardRecruiterName}>
                                    <div className={classes.jobDescription}>{pipeline.candidate_first_name}</div>
                                    <div className={classes.jobDescription}>{pipeline.candidate_last_name}</div>
                                </div>
                                
                            </div>
                        
                            <div className={classes.cardRecruiterContact}>
                                <div className={classes.jobDescription}>{pipeline.phone}</div>
                                <div className={classes.jobDescription}>{pipeline.candidate_email}</div>
                                <div className={classes.jobDescription}>
                                    <a style={{textDecoration: "none", color: "#000000"}} href={pipeline.linkedin_url} target="_blank">
                                    <img style={{cursor:'pointer', width:'6em', height:'1.7em', marginTop:"1em", borderRadius: '5px'}} src={LinkedinLogo} alt="Linkedin Logo"/>
                                    </a>
                                </div>

                            </div>
                            
                        </div>
                        <Box sx={{ width: '100%'}} className={classes.pipelineProgress}>
                            <Stepper activeStep={((pipeline.status_id)<4 ? pipeline.status_id-1 : 4)} alternativeLabel>
                                {progress.map((label) => (
                                <Step key={label.id} sx={{
                                    '& .MuiStepLabel-root .Mui-completed': {
                                      color: 'second.main',
                                      fontWeight: '700', // circle color (COMPLETED)
                                    },
                                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                      {
                                        color: 'second.main',
                                        fontWeight: '700', // Just text label (COMPLETED)
                                      },
                                    '& .MuiStepLabel-root .Mui-active': {
                                      color: 'second.main',
                                      fontWeight: '700', // circle color (ACTIVE)
                                    },
                                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                      {
                                        color: 'second.main',
                                        fontWeight: '700', // Just text label (ACTIVE)
                                      },
                                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                      fill: 'second.main',
                                      fontWeight: '700', // circle's number (ACTIVE)
                                    },
                                    '& .MuiStepLabel-alternativeLabel': {
                                        fontWeight: '700', // circle's number (ACTIVE)
                                      },
                                  }}>
                                    <StepLabel className={classes.stepperLabel}>{label.label}</StepLabel>
                                </Step>
                                ))}
                            </Stepper>
                            {pipeline.status_id<4&&<div className={classes.pipelineButton}>
                                <button className={classes.addRespButton} onClick={updatePipeline}>Advance</button>
                            </div>}
                            {pipeline.status_id===4&&<div className={classes.pipelineButton} style={{display:'block'}}>
                            {pipeline.payment_type===0&&
                              <TextField
                              id="outlined-multiline-flexible"
                              label="Salary"
                              multiline
                              maxRows={3}
                              className={classes.addSalaryInput}
                              value={salary}
                              onChange={handleSalaryChange}
                              required
                              type="text"
                            />
                            }
                                <button className={classes.addRespButton} onClick={approveHire}>Approve hire</button>
                            </div>}
                            
                        </Box>
                </div>
                </div>
                
        </Box>
        
        }
        {!pipeline&&
        <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
            <Skeleton variant="rectangular" width="100%" height={60} />
            
            <Skeleton variant="rounded" width="100%" height={60}  />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rounded" width="100%" height={60}  />
        </Stack>
}
        </Fragment>
        )

}

export default Pipeline