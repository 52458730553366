import { createSlice } from '@reduxjs/toolkit';

const companyListingSlice = createSlice({
  name: 'companyListings',
  initialState: {
    companyListingsList:[],
    listingDetails:{},
    listingPaymentDetails:{},
    contractDetails:{},
    listingId:"",
    companyPendingListingContracts:[],
    companyApprovedListingContracts:[],
    listingPipelines:[],
    companyAllContracts:{},
    contractsList:[],
    requestsList:[],
    pipelinesList:[],
    pipelineDetails:{},
    loading: false,
    error: null,
  },
  reducers: {
    companyGetListingsList(state, action) {
        state.companyListingsList = action.payload.listingsList
        state.loading = false
    },
    companyGetListingDetails(state, action) {
        state.listingDetails = action.payload.listingDetails
        state.loading = false
    },
    companyGetPaymentDetails(state, action) {
      state.listingPaymentDetails = action.payload.paymentDetails
      state.loading = false
  },
    getListingBegin(state, action) {
      state.loading = true
  },
    getListingError(state, action) {
      state.error = action.payload.error
      state.listingDetails = null
  },
    companySetListingId(state, action) {
        state.listingId = action.payload.listingId
    },
    companyCleanListingDetails(state, action) {
        state.listingDetails = {}
    },
    companyGetListingContracts(state, action) {
      state.companyPendingListingContracts = action.payload.listingContracts.pending
      state.companyApprovedListingContracts = action.payload.listingContracts.approved
    },
    getListingPipelines(state, action) {
      state.listingPipelines = action.payload.listingPipelines
    },
    companyGetAllListingContracts(state, action) {
      state.companyAllContracts = action.payload.listingContracts
    },
    companyGetContractDetails(state, action) {
      state.contractDetails = action.payload.contractDetails
    },
    companyGetContractsList(state, action) {
      state.loading = false
      state.contractsList = action.payload.contractsList
    },
    companyGetRequestsList(state, action) {
      state.loading = false
      state.requestsList = action.payload.requestsList
    },
    companyGetPipelinesList(state, action) {
      state.pipelinesList = action.payload.pipelinesList
      state.loading = false
    },
    getPipelineListBegin (state, action) {
      state.loading = true
    },
    getPipelineListError (state, action) {
      state.error = action.payload.error
      state.pipelinesList = null
    },
    getContractsListBegin (state, action) {
      state.loading = true
    },
    getContractsListError (state, action) {
      state.error = action.payload.error
      state.contractsList = null
    },
    companyGetPipelineDetails(state, action) {
      state.pipelineDetails = action.payload.pipelineDetails
    },
  },
});

export const companyListingActions = companyListingSlice.actions;

export default companyListingSlice;
