import {useParams} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { getListingDetails, updateListing } from "../../store/companyListings-action";
import { Fragment } from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Listing.module.css'
import ListingCard from "./ListingCard";
import MenuItem from '@mui/material/MenuItem';
import Quill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Container } from '@material-ui/core';
import axios from 'axios';
import RichTextEditor from "../UI/RichTextEditor";



const ListingEdit = ()=>{


    const office_type = ["On-site", "Remote", "Hybrid"];
    const jobType = ["Full-time", "Part-time", "Contract"]


    const {listingId} = useParams()
    const dispatch = useDispatch()

    const [listingDetails, setListingDetails] = useState({})
    const [description, setDescription] = useState("")
    const [genDescription, setGenDescription] = useState("")
    const [qualification, setQualification] = useState("")
    const [link, setLink] = useState("")
    const [minSalary, setMinSalary] = useState("")
    const [maxSalary, setMaxSalary] = useState("")
    const [allQualififcation, setAllQualification] = useState([])
    const [allLink, setAllLink] = useState([])
    const [listingJobType, setListingJobType] = useState("")
    const [listingWorkplaceType, setListingWorkplaceType] = useState("")
    const [resumeText, setResumeText] = useState('');
    const [pageLoaded, setPageLoaded] = useState(false)

    const loading = useSelector((state) => state.companyListings.loading);
    const error = useSelector((state) => state.companyListings.error);


    useEffect(()=>{
        const fetchData = async ()=>{

            await dispatch(getListingDetails(listingId))
            
        }
        fetchData()
        

    },[dispatch])
    

    const listingDetailsRes = useSelector(state=>(state.companyListings.listingDetails))

    // useEffect(()=>{
    //     // generateCareers()
    //     const fetchData = async ()=>{

    //     if (description.length < 1){

    //         const data = await generateCareers()

    //     setDescription(prevState => (
    //         data
    //       ))
    //     }
    // }
    // fetchData()

        
    // },[listingDetailsRes])
    

    useEffect(()=>{
      if (listingDetailsRes.description){
        
        if (listingDetailsRes.description){
          
            setDescription(listingDetailsRes.description)
        }

        if (listingDetailsRes.salary_range){
            setMinSalary(listingDetailsRes.salary_range.min_val)
        }

        if (listingDetailsRes.salary_range){
            setMaxSalary(listingDetailsRes.salary_range.max_val)
        }

        if (listingDetailsRes.qualifications){
            setAllQualification(listingDetailsRes.qualifications)
        }

        if (listingDetailsRes.job_links){
            setAllLink(listingDetailsRes.job_links)
        }

        if (listingDetailsRes.workplace_type){
            setListingWorkplaceType(listingDetailsRes.workplace_type)
        }

        if (listingDetailsRes.employment_type){
            setListingJobType(listingDetailsRes.employment_type)
        }
        setPageLoaded(true)
      }
        
    },[listingDetailsRes])

    const handleQualificationChange = (event) =>{
        setQualification(event.target.value)
    }

    const generateResponsibilities = async () => {
        // Replace with your OpenAI API key
        const apiKey = process.env.REACT_APP_OPENAI_API_KEY;        
    
        // Prepare the prompt
        const prompt = `Generate a job description for a ${listingDetails.title} at a company named ${listingDetails.company_name}. Just show the responsibilities section. Responsibilities:`;
        const messages = [
          {"role": "system", "content": "You are an expert in career coaching and are very helpful at suggesting careers to people."},
          {"role": "user", "content": prompt},
      ]

    
        try {
          const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
              max_tokens: 2048,
              n: 1,
              stop: null,
              temperature: 0.8,
              model: "gpt-3.5-turbo",
              messages : messages
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
              },
            }
          );
          const generatedText = response.data.choices[0].message.content;
          
          const lines = generatedText.split('\n').filter((line) => line);
          return lines;
        } catch (error) {
          console.error('Error generating careers:', error);
          return [];
        }
      };


      const generateDescription = async () => {
        // Replace with your OpenAI API key
        const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

    
        // Prepare the prompt
        const prompt = `Generate a job description for a ${listingDetailsRes.title} at a company named ${listingDetailsRes.company_name}. Just show the description section without the qualification or responsibilities or anything else. Job Description:`;
        const messages = [
          {"role": "system", "content": "You are an expert in career coaching and are very helpful at suggesting careers to people."},
          {"role": "user", "content": prompt},
      ]

    
        try {
          const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
              max_tokens: 2048,
              n: 1,
              stop: null,
              temperature: 0.8,
              model: "gpt-3.5-turbo",
              messages : messages
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
              },
            }
          );
          const generatedText = response.data.choices[0].message.content;
          
          const lines = generatedText.split('\n').filter((line) => line);
          setDescription(generatedText)
          return lines;
        } catch (error) {
          console.error('Error generating careers:', error);
          return [];
        }
      };

    const handleDescriptionChange = (content) =>{
        setDescription(content)
    }

    const handleLinkChange = (event) =>{
        setLink(event.target.value)
    }

    const handleMinSalaryChange = (event) =>{
        setMinSalary(event.target.value)
    }

    const handleMaxSalaryChange = (event) =>{
        setMaxSalary(event.target.value)
    }

    const handleResumeChange = (value) => {
        setResumeText(value);
      };


    const handleAddQualification = (event) =>{
        event.preventDefault()
        setAllQualification(oldQualification => [...oldQualification, qualification]);
        setQualification("")
    }

    const handleAddLink = (event) =>{
        event.preventDefault()
        setAllLink(oldLink => [...oldLink, link]);
        setLink("")
    }

    const handleRemoveSkill = (event) =>{
        event.preventDefault()
        setAllQualification(allQualififcation.filter((skill, index)=> index!==parseInt(event.currentTarget.id))
        )
    }

    const handleRemoveLink = (event) =>{
        event.preventDefault()
        setAllLink(allLink.filter((link, index)=> index!==parseInt(event.currentTarget.id))
        )
    }

    const handleEditListing = (event) =>{
        event.preventDefault()
        dispatch(updateListing(listingId, description, allQualififcation, {min_val:minSalary, max_val:maxSalary}, allLink, listingJobType, listingWorkplaceType))
        
    }

    const handleWorkplaceTypeChange = (event) =>{
        event.preventDefault(event.target.value)
        setListingWorkplaceType(event.target.value)
    }

    const handleJobTypeChange = (event) =>{
        event.preventDefault()
        setListingJobType(event.target.value)
    }

    if (loading) {
      return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
      <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
      <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
      <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
      <Skeleton variant="rectangular" width="100%" height={60} />
      
      <Skeleton variant="rounded" width="100%" height={60}  />
      <Skeleton variant="rectangular" width="100%" height={60} />
      <Skeleton variant="rounded" width="100%" height={60}  />
  </Stack>
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!listingDetailsRes) {
      return null;
    }


    return (
        <Fragment>
        <Box style={{marginTop:"3em"}}>

        <Box className={classes.listingsContainer}>
        <Box className={classes.listingCardDetails}><ListingCard {...listingDetailsRes}/></Box>

            <div>
            <RichTextEditor changeDescription={handleDescriptionChange} description={description} title={listingDetailsRes.title} company_name={listingDetailsRes.company_name} />
            </div>
            <div className={classes.editListContainer}><div className={classes.jobSubTitle}>Qualifications</div>
            <div className={classes.editNewListingContainer}><TextField
          id="outlined-multiline-flexible"
          label="New skill"
          multiline
          value={qualification}
          maxRows={4}
          className={classes.addRespInput}
          onChange={handleQualificationChange}
        />
        <button className={classes.addRespButton} onClick={handleAddQualification}>
            <span>Add</span>
        </button>
        </div>
        <div>{allQualififcation.map((skill, index)=>{
                return (<div key={index} className={classes.bubbleListContainer}><div className={classes.bubbleList}>
                    <span>{skill}</span>
                    <button id={index} onClick={handleRemoveSkill}>
                <span>x</span>
            </button>
                    </div></div>)
            })}</div>

            </div>
            <div className={classes.editListContainer}><div className={classes.jobSubTitle}>Job related links</div>
            </div>
            <div className={classes.editNewListingContainer}><TextField
          id="outlined-multiline-flexible"
          label="New link"
          multiline
          maxRows={4}
          className={classes.addRespInput}
          value={link}
          onChange={handleLinkChange}
        />
        <button className={classes.addRespButton} onClick={handleAddLink}>
            <span>Add</span>
        </button>
        </div>
        <div>{allLink.map((link, index)=>{
                return (<div key={index} className={classes.bubbleListContainer}><div className={classes.bubbleList}>
                    <span>{link}</span>
                    <button id={index} onClick={handleRemoveLink}>
                <span>x</span>
            </button>
                    </div></div>)
            })}</div>
            <div className={classes.editListContainer}><div className={classes.jobSubTitle}>Salary range</div>
            </div>
            <div className={classes.editNewListingContainer}><TextField
          id="outlined-multiline-flexible"
          label="Min salary"
          multiline
          maxRows={4}
          className={classes.addSalaryInput}
          value={minSalary}
          onChange={handleMinSalaryChange}
        />
        <TextField
          id="outlined-multiline-flexible"
          label="Max salary"
          multiline
          maxRows={4}
          className={classes.addSalaryInput}
          value={maxSalary}
          onChange={handleMaxSalaryChange}
        />
        </div>

        <div className={classes.editListContainer}>

        <TextField
          id="workplaceType"
          select
          label="Workplace type"
          defaultValue="Workplace type"
          value={listingWorkplaceType}
          className={classes.editListingFormFullField}
          onChange={handleWorkplaceTypeChange}
        >
          {office_type.map((office) => (
            <MenuItem key={office} value={office}>
              {office}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="jobType"
          select
          label="Job type"
          defaultValue="Job type"
          value={listingJobType}
          className={classes.editListingFormFullField}
          onChange={handleJobTypeChange}
        >
          {jobType.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>

        </div>

            {/* <div style={{display:"inline-flex"}}>{listingDetails.workplace_type!=null&&listingDetails.workplace_type.length>0&&<div><div className={classes.jobSubTitle}><div className={classes.detailsSpans}><span>{listingDetails.workplace_type}</span></div></div></div>}
            {listingDetails.employment_type!=null&&listingDetails.employment_type.length>0&&<div><div className={classes.jobSubTitle}><div className={classes.detailsSpans}><span>{listingDetails.employment_type}</span></div></div></div>}
            </div> */}

            <button className={classes.updateListingButton} onClick={handleEditListing}>
                <span>Update listing</span>
            </button>
            

        </Box>
        </Box>

        </Fragment>
        )

}

export default ListingEdit