import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Auth.module.css';
import { useState } from 'react';
import Divider from '@mui/material/Divider';

import { Fragment } from 'react';


import { useSelector, useDispatch } from 'react-redux';
import { companySignUp, recruiterSignUp } from '../../store/companyProfile-actions';

import {useNavigate} from "react-router-dom";




const CompanySignUp = () =>{

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const profile = useSelector((state) => state.companyProfile);

    const [companyEmail, setCompanyEmail] = useState('')
    const [companyPassword, setCompanyPassword] = useState('')
    const [companyPhone, setCompanyPhone] = useState('')
    const [companyNoEmployees, setCompanyNoEmployees] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyManName, setCompanyManName] = useState('')
    



    const handleCreateCompany = (event) =>{
        event.preventDefault()
        dispatch(companySignUp(companyEmail,companyPassword, companyName, companyNoEmployees, companyPhone, companyManName))
        setCompanyEmail('')
        setCompanyPassword('')
        setCompanyManName('')
        setCompanyName('')
        setCompanyNoEmployees('')
        setCompanyPhone('')
        navigate('/company')
    }

    const handleCompanyEmailChange = (event) =>{
        event.preventDefault()
        setCompanyEmail(event.target.value)
    }

    const handleCompanyPasswordChange = (event) =>{
        event.preventDefault()
        setCompanyPassword(event.target.value)
    }

    const handleCompanyNameChange = (event) =>{
      event.preventDefault()
      setCompanyName(event.target.value)
    }

    const handleCompanyNoEmployeesChange = (event) =>{
      event.preventDefault()
      setCompanyNoEmployees(event.target.value)
    }

    const handleCompanyPhoneChange = (event) =>{
      event.preventDefault()
      setCompanyPhone(event.target.value)
    }

    const handleCompanyManNameChange = (event) =>{
      event.preventDefault()
      setCompanyManName(event.target.value)
    }




    return (
        <Fragment>
        <Container>
            <Box className="signUpForm"
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        marginLeft:"auto",
        marginRight:'auto',
        marginTop: "4em",
        marginBottom: "4em",
        width: "fit-content"
      }}
      noValidate
      autoComplete="off"
    >
        <TextField
          required
          id="outlined-required"
          label="Full name"
          value={companyManName}
          onChange={handleCompanyManNameChange}
        />
        <TextField
          required
          id="outlined-required"
          label="Work email"
          value={companyEmail}
          onChange={handleCompanyEmailChange}
        />
        <TextField
          required
          id="outlined-required"
          label="Company name"
          value={companyName}
          onChange={handleCompanyNameChange}
        />
        <TextField
          id="outlined-number-required"
          label="No. of employees"
          type="number"
          value={companyNoEmployees}
          onChange={handleCompanyNoEmployeesChange}
        />
        <TextField
          id="outlined-number-required"
          label="Company phone"
          value={companyPhone}
          onChange={handleCompanyPhoneChange}
        />
        <TextField
          required
          id="outlined-password-input"
          label="Set password"
          type="password"
          autoComplete="current-password"
          value={companyPassword}
          onChange={handleCompanyPasswordChange}
        />
        {/* <TextField
          required
          id="outlined-password-input"
          label="Confirm password"
          type="password"
          autoComplete="current-password"
        /> */}

<button onClick={handleCreateCompany} className={classes.button}>
      <span>Sign Up</span>
    </button>
                
    </Box>



        </Container>
        </Fragment>
    )
}

export default CompanySignUp