import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Fragment } from 'react';
import classes from './Listing.module.css';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Location = (props) =>{
    if (props.state==="Remote"){
        return <div>{props.state}</div>
    }
    else if (!props.city){
        return <div>{props.country}</div>
    }
    else{
        return <div>{props.city}, {props.state}, {props.country}</div>
    }
}

const ListingCard = (props)=>{
    const navigate = useNavigate();
    const created_date = new Date(props.created_at)


    return (
        <Fragment>
            <img style={{cursor:'pointer', width:'3em', height:'3em', border:'1px solid lightgray', borderRadius: '5px'}} src={props.company_pic} alt="Logo" />
            <Box sx={{marginLeft:'1em'}}>
            <Link className={classes.listingTitle} to={`/company/listings/${props.id}`}>{props.title}</Link>
                <div className={classes.listingLocation}><Location {...props}/></div>
            </Box>
        </Fragment>
        
    )
}

export default ListingCard