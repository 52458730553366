import { Fragment } from "react"
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import classes from './Recruiter.module.css'
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { recruiterGetFullProfile, updateRecruiterProfile } from '../../store/recruiterProfile-actions';
import { useEffect, useState } from "react";
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';



const RecruiterProfile = ()=>{

    var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
  const LOCAL_DOMAIN = "http://0.0.0.0:8000"
  const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

  if (process.env.REACT_APP_ENV==='local'){
    BASE_DOMAIN=LOCAL_DOMAIN
  }
  else if (process.env.REACT_APP_ENV==='test'){
    BASE_DOMAIN=TEST_DOMAIN
  }

    const dispatch = useDispatch()
    const profile = useSelector((state) => state.recruiterProfile.recruiterProfileDetails);
    const error = useSelector((state) => state.recruiterProfile.error);
    const loading = useSelector((state) => state.recruiterProfile.loading);
    const [recEmail, setRecEmail] = useState("")
    const [recPhone, setRecPhone] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [linkedinUrl, setLinkedinUrl] = useState("")
    const [personalUrl, setPersonalUrl] = useState("")
    const [twitterUrl, setTwitterUrl] = useState("")
    const [resumeUrl, setResumeUrl] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [country, setCountry] = useState("")
    const [description, setDescription] = useState("")
    const [file, setFile] = useState(null);

    const fetchData = async () => {
        await dispatch(recruiterGetFullProfile());
    };
  

    useEffect(() => {
        
        fetchData();

      }, [dispatch]);
    
    useEffect(() => {
        if (profile){
            setRecEmail(profile.email)
          setFirstName(profile.recruiter_name)
          setLastName(profile.last_name)
          setRecPhone(profile.phone)
          setLinkedinUrl(profile.linkedin_url)
          setPersonalUrl(profile.personal_url)
          setTwitterUrl(profile.twitter_url)
          setResumeUrl(profile.resume_url)
          setCity(profile.city)
          setState(profile.state)
          setCountry(profile.country)
          setDescription(profile.description)
        }
        
    }, [profile]);

    
    const handleEditRecruiter = async (event)=>{
        event.preventDefault()
        
        if (file) {
            const formData = new FormData();
            formData.append('profile_picture', file);
      
            try {
              const response = await axios.post(`${BASE_DOMAIN}/account/recruiter/${localStorage.getItem('user_id')}/image/`, formData);
            } catch (error) {
              console.error('Error uploading profile picture:', error);
            }
          }

        dispatch(updateRecruiterProfile(recEmail, firstName, lastName, recPhone, linkedinUrl, personalUrl, twitterUrl, resumeUrl, city, state, country, description))
    }

    const handleRecEmailChange =(event) =>{
        event.preventDefault()
        setRecEmail(event.target.value)
    }

    const handleRecPhoneChange =(event) =>{
        event.preventDefault()
        setRecPhone(event.target.value)
    }

    const handleRecNameChange =(event) =>{
        event.preventDefault()
        setFirstName(event.target.value)
    }

    const handleLNameChange =(event) =>{
        event.preventDefault()
        setLastName(event.target.value)
    }

    const handleLinkedinUrlChange =(event) =>{
        event.preventDefault()
        setLinkedinUrl(event.target.value)
    }

    const handleResumeUrlChange =(event) =>{
        event.preventDefault()
        setResumeUrl(event.target.value)
    }

    const handlePersonalUrlChange =(event) =>{
        event.preventDefault()
        setPersonalUrl(event.target.value)
    }

    const handleTwitterUrlChange =(event) =>{
        event.preventDefault()
        setTwitterUrl(event.target.value)
    }

    const handleCityChange =(event) =>{
        event.preventDefault()
        setCity(event.target.value)
    }

    const handleStateChange =(event) =>{
        event.preventDefault()
        setState(event.target.value)
    }

    const handleCountryChange =(event) =>{
        event.preventDefault()
        setCountry(event.target.value)
    }

    const handleDescriptionChange =(event) =>{  
        event.preventDefault()
        setDescription(event.target.value)
    }
    

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
      };


      if (loading) {
        return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="rectangular" width="100%" height={60} />
        
        <Skeleton variant="rounded" width="100%" height={60}  />
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60}  />
    </Stack>
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }


      if (!profile) {
        return <div>Sorry, we could not find your profile</div>;
      }
      


    return (
        <Fragment>
            <CssBaseline />
      <Container fixed>
        <Box sx={{ marginTop: '4em', paddingBottom: '4em' }}>
            <Box sx={{ marginTop: '2em', display: 'inline-flex', width:'70%', marginLeft:'auto', marginRight:'auto' }}>
                <Box sx={{display: 'block', marginLeft:'3em', width:'100%'}}>
                <div>
                        <input type="file" onChange={handleFileChange} />
                </div>
                    <TextField onChange={handleRecEmailChange} className={classes.profileInputs} id="outlined-search" label="Email" value={recEmail} />
                    <TextField onChange={handleRecNameChange} className={classes.profileInputs} id="outlined-search" label="First name" value={firstName} />
                    <TextField onChange={handleLNameChange} className={classes.profileInputs} id="outlined-search" label="Last name" value={lastName} />
                    <TextField className={classes.profileInputs} id="outlined-search" label="Password" value="randomnum" type="password" />
                    <TextField onChange={handleRecPhoneChange} className={classes.profileInputs} id="outlined-search" label="Phone number" value={recPhone} />
                    <TextField onChange={handleLinkedinUrlChange} className={classes.profileInputs} id="outlined-search" label="LinkedIn url" value={linkedinUrl} />
                    <TextField onChange={handleResumeUrlChange} className={classes.profileInputs} id="outlined-search" label="Resume url" value={resumeUrl} />
                    <TextField onChange={handlePersonalUrlChange} className={classes.profileInputs} id="outlined-search" label="Personal url" value={personalUrl} />
                    <TextField onChange={handleTwitterUrlChange} className={classes.profileInputs} id="outlined-search" label="Twitter" value={twitterUrl} />
                    <TextField onChange={handleCityChange} className={classes.profileInputs} id="outlined-search" label="City" value={city} />
                    <TextField onChange={handleStateChange} className={classes.profileInputs} id="outlined-search" label="State" value={state} />
                    <TextField onChange={handleCountryChange} className={classes.profileInputs} id="outlined-search" label="Country" value={country} />
                    <TextField onChange={handleDescriptionChange} className={classes.profileInputs} id="outlined-search" label="Description" value={description} />
                    <button onClick={handleEditRecruiter} className={classes.button}>
                        <span>Save</span>
                    </button>
                </Box>
                
            </Box>
            
        </Box>
      </Container>
      
        </Fragment>
    )
}

export default RecruiterProfile