import Box from '@mui/material/Box';
import { Fragment } from 'react';
import classes from './Listing.module.css';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';


const CandidateCard = (props)=>{
    const navigate = useNavigate();
    const created_date = new Date(props.created_at)


    const navToListing = ()=>{
        navigate(`/company/contracts/${props.candidate_id}`)
      }

    return (
        <Fragment>
            {props.profile_pic===""&&
            <Avatar>{props.first_name[0]}{props.last_name[0]}</Avatar>
            }
            {props.profile_pic!==""&&<img style={{cursor:'pointer', width:'3em', height:'3em', border:'1px solid lightgray', borderRadius: '5px'}} src={props.profile_pic} alt="Logo" onClick={navToListing} />}
            <Box sx={{marginLeft:'1em'}}>
                <div className={classes.listingTitle}>{props.first_name}</div>
                <div className={classes.listingCompany}>{props.last_name}</div>
                <div className={classes.pipelineStatus}>{props.status}</div>
            </Box>
        </Fragment>
        
    )
}

export default CandidateCard