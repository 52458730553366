import { Fragment } from "react"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import classes from './Tile.module.css'
import { useNavigate } from "react-router-dom";

const AccountTile = (props) => {

    const navigate = useNavigate();

    const navToPage = ()=>{
        navigate(props.page)
    }

    return(
        <Fragment>
            <Card className={classes.accountCard} onClick={navToPage}>
                <CardContent>
                    <main>{props.children}</main>
                    <Typography className={classes.tileTitle} color="text.secondary" gutterBottom>
                    {props.title}
                    </Typography>
                    <Typography className={classes.tileSubTitle} variant="body2">
                    {props.subTitle}
                    </Typography>
                </CardContent>
            </Card>
        </Fragment>

    )
}

export default AccountTile;