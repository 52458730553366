import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Listing.module.css';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createListing } from '../../store/companyListings-action';
import {useNavigate} from "react-router-dom";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';




const NewListing = () =>{

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const listingDetailsRes = useSelector(state=>(state.companyListings))


    const [listingTitle, setListingTitle] = useState('')
    const [listingJobType, setListingJobType] = useState('full')
    const [listingWorkplaceType, setListingWorkplaceType] = useState('onsite')
    const [listingCity, setListingCity] = useState('')
    const [listingState, setListingState] = useState('')
    const [listingCountry, setListingCountry] = useState('United States')
    const [redirectActive, setRedirectActive] = useState(false)
    const [listingDepartment, setListingDepartment] = useState([])
    const [paymentAmount, setPaymentAmount] = useState("")
    const [paymentType, setPaymentType] = useState("")
    const [paymentPeriod, setPaymentPeriod] = useState("")

    const [listingDescription, setListingDescription] = useState('')
    const [listingQualifications, setListingQualifications] = useState([])
    const [listingJobLinks, setListingJobLinks] = useState([])
    const [listingSalaryRange, setListingSalaryRange] = useState([])




    const handleCreateListing = (event) =>{
        event.preventDefault()
        dispatch(createListing(
          listingTitle,
          listingCity, 
          listingState, 
          listingCountry, 
          listingWorkplaceType, 
          listingJobType, 
          listingDepartment,
          paymentAmount,
          paymentType,
          paymentPeriod,
          ))
               

        setListingTitle('')
        setListingJobType('')
        setListingWorkplaceType('')
        setListingCity('')
        setListingState('')
        setPaymentAmount('')
        setPaymentType('')
        setPaymentPeriod('')
        // setListingDescription('')
        // setListingQualifications('')
        // setListingJobLinks('')
        // setListingSalaryRange('')
        
        
    }

    useEffect(()=>{
        if (redirectActive){
            navigate(`/company/listing/edit/${listingDetailsRes.listingId}`) 
        }
        setRedirectActive(true)
        
    }, [listingDetailsRes])



    const handleJobTitleChange = (event) =>{
      event.preventDefault()
      setListingTitle(event.target.value)
    }

    const handleCityChange = (event) =>{
        event.preventDefault()
        setListingCity(event.target.value)
    }

    const handleStateChange = (event) =>{
        event.preventDefault()
        setListingState(event.target.value)
    }

    const handleWorkplaceTypeChange = (event) =>{
        event.preventDefault(event.target.value)
        setListingWorkplaceType(office_type[event.target.value])
    }

    const handleJobTypeChange = (event) =>{
        event.preventDefault()
        setListingJobType(jobType[event.target.value]);
    }

    const handleDepartmentChange = (event) =>{
      setListingDepartment(event.target.value);
  }

  const handlePaymentAmountChange = (event) =>{
    event.preventDefault()
    setPaymentAmount(event.target.value)
}

const handlePaymentTypeChange = (event) =>{
    event.preventDefault(event.target.value)
    setPaymentType(event.target.value)
}

  const handlePaymentPeriodChange = (event) =>{
      event.preventDefault(event.target.value)
      setPaymentPeriod(event.target.value)
  }

  const departments = [
    'Engineering', 'Design', 'Human Resources', 'Operations', 'Finance', 'Sales', 'Marketing', 'R&D', 'Customer service', 'Legal', 'Admin', 'Other'
  ];
  const paymentTypes = {0:"%", 1:"$"};
    const paymentPeriods = [1,2,3];

    const office_type = {"On-site":"onsite", "Remote":"remote", "Hybrid":"hybrid"};

    const stateNames = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
        'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
        'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 
        'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 
        'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
      ];

    const jobType = {"Full-time":"full", "Part-time":"part", "Contract":"contract"}




    return (
        <Fragment>
        <Container>
            <Box className={classes.listingFormStepOne}
      component="form"
      noValidate
      autoComplete="off"
    >
        <TextField
          required
          id="jobTitle"
          label="Job title"
          className={classes.listingFormFullField}
          onChange={handleJobTitleChange}
          
        />
        <div className={classes.formFieldGroup}>
        <TextField
          id="city"
          label="City"
          className={classes.listingFormHalfField}
          style={{marginRight:"8px"}}
          onChange={handleCityChange}
          
        />
        <TextField
          id="state"
          required
          select
          label="State"
          defaultValue=""
          className={classes.listingFormHalfField}
          style={{marginLeft:"8px"}}
          onChange={handleStateChange}
        >
          {stateNames.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </TextField>
        </div>
        <TextField
          disabled
          id="country"
          label="United States"
          className={classes.listingFormFullField}
          
        />
        <FormControl fullWidth className={classes.listingFormFullField}>
          <InputLabel id="department-label">Department</InputLabel>
          <Select
            labelId="department-label"
            id="department"
            multiple
            value={listingDepartment}
            onChange={handleDepartmentChange}
            label="Department"
          >
            {departments.map((department) => (
              <MenuItem key={department} value={department}>
                {department}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="workplaceType"
          select
          label="Workplace type"
          defaultValue="On-site"
          className={classes.listingFormFullField}
          onChange={handleWorkplaceTypeChange}
        >
          {Object.keys(office_type).map((office) => (
            <MenuItem key={office} value={office}>
              {office}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="jobType"
          select
          label="Job type"
          defaultValue="Full-time"
          className={classes.listingFormFullField}
          onChange={handleJobTypeChange}
        >
          {Object.keys(jobType).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        <div className={classes.formFieldGroup}>
        <TextField
          id="paymentAmount"
          label="Payment Amount"
          type="number"
          defaultValue="0"
          className={classes.listingFormFullField}
          style={{marginRight:"8px"}}
          onChange={handlePaymentAmountChange}
          
        />
        <TextField
          id="paymentType"
          select
          label="Payment type"
          defaultValue={0}
          className={classes.listingFormFullField}
          onChange={handlePaymentTypeChange}
        >
          {Object.keys(paymentTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {paymentTypes[type]}
            </MenuItem>
          ))}
        </TextField>
        </div>
        <TextField
          id="paymentPeriod"
          select
          label="Payment period"
          defaultValue="1"
          className={classes.listingFormFullField}
          onChange={handlePaymentPeriodChange}
        >
          {paymentPeriods.map((period) => (
            <MenuItem key={period} value={period}>
              {period}
            </MenuItem>
          ))}
        </TextField>


<button onClick={handleCreateListing} className={classes.button}>
      <span>Create listing</span>
    </button>
                
    </Box>




        </Container>
        </Fragment>
    )
}

export default NewListing