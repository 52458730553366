import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { sendPayment } from '../../store/companyListings-action';
import { useDispatch } from 'react-redux';
import { updateDefaultPayment } from '../../store/companyProfile-actions';
import classes from './Company.module.css'

const CompanyCardInfo = (props) => {
    const dispatch = useDispatch()
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;
  
    setProcessing(true);
  
    const card = elements.getElement(CardElement);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });
  
    if (result.error) {
      setError(result.error.message);
      setProcessing(false);
    } else {
        dispatch(updateDefaultPayment(props.company_id, result.paymentMethod.id))
        setProcessing(false);
    }
  };

  const handleSubmitPayment = async (event) => {
    event.preventDefault();
    setProcessing(true);
    await dispatch(sendPayment(props.company_id, 1000))
    setProcessing(false);
  };

  return (
    <div>
    <form onSubmit={handleSubmit} style={{width:'100%'}}>
      <CardElement />
      <button disabled={!stripe || processing} type="submit" style={{marginTop:'2em'}} className={classes.button}>
        Update Card
      </button>
      {error && <div>{error}</div>}
    </form>
    {/* <button onClick={handleSubmitPayment}>Send payment</button> */}
    </div>
  );
};

export default CompanyCardInfo;