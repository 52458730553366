import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Auth.module.css';
import { Fragment } from 'react';


import { useSelector, useDispatch } from 'react-redux';
import { companyLogIn, companyGetFullProfile } from '../../store/companyProfile-actions';
import { useState } from 'react';




const CompanySignIn = () =>{
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.companyProfile);

    const [companyEmail, setCompanyEmail] = useState('')
    const [companyPassword, setCompanyPassword] = useState('')

    const handleCompanySignIn = async (event) =>{
        event.preventDefault()
        await dispatch(companyLogIn(companyEmail,companyPassword))
        await dispatch(companyGetFullProfile())
        setCompanyEmail('')
        setCompanyPassword('')
    }

    const handleCompanyEmailOnChange = (event) =>{
        event.preventDefault()
        setCompanyEmail(event.target.value)
    }

    const handleCompanyPasswordOnChange = (event) =>{
        event.preventDefault()
        setCompanyPassword(event.target.value)
    }

    return (
        <Fragment>
        {!(profile.companyLoggedIn)&&<Container sx={{display: 'flex', marginTop: '4em'}}>
            {profile.companyLoggedIn&&<Box>User is logged in</Box>}
            <Box className="signUpForm"
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        marginLeft:"auto",
        marginRight:'auto',
        marginTop: "4em",
        marginBottom: "4em",
        width: "fit-content"
      }}
      noValidate
      autoComplete="off"
    >
        <h2>Log In</h2>
        <TextField
          required
          id="outlined-required"
          label="Email Adress"
          onChange={handleCompanyEmailOnChange}
          value={companyEmail}
        />
        <TextField
          required
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={handleCompanyPasswordOnChange}
          value={companyPassword}
        />

        <button onClick={handleCompanySignIn} className={classes.button}>
      <span>Sign In</span>
    </button>
        
                
    </Box>

        </Container>}
        </Fragment>
    )
}

export default CompanySignIn