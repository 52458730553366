import classes from './SignUpButton.module.css';
import {useNavigate} from "react-router-dom";



const SignInButton = (props) => {

  const navigate = useNavigate()

  const handleSignIn = ()=>{
      navigate(props.linkTo)
    }  

  return (
    <button onClick={handleSignIn} className={classes.button}>
      <span>Sign in</span>
    </button>
  );
};

export default SignInButton;