import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Auth.module.css';
import { Fragment } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { candidateAuth } from '../../store/candidateProfile-actions';
import { useState } from 'react';




const CandidateSignIn = () =>{
    const dispatch = useDispatch();

    const [canEmail, setCanEmail] = useState('')

    const handleCandidateAuth = async (event) =>{
        event.preventDefault()
        await dispatch(candidateAuth(canEmail))
        setCanEmail('')
    }

    const handleCanEmailOnChange = (event) =>{
        event.preventDefault()
        setCanEmail(event.target.value)
    }

    return (
        <Fragment>
        <Container sx={{display: 'flex', marginTop: '4em'}}>
        
            <Box className="signUpForm"
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        marginLeft:"auto",
        marginRight:'auto',
        marginTop: "4em",
        marginBottom: "4em",
        width: "fit-content"
      }}
      noValidate
      autoComplete="off"
    >
        <h2>Log In</h2>
        <TextField
          required
          id="outlined-required"
          label="Email Adress"
          onChange={handleCanEmailOnChange}
          value={canEmail}
        />

        <button onClick={handleCandidateAuth} className={classes.button}>
      <span>Sign In</span>
    </button>
        
                
    </Box>

        </Container>
        </Fragment>
    )
}

export default CandidateSignIn