import './App.css';
import { Fragment } from 'react';
import MarketingPage from './components/Layout/MarketingPage';
import Company from './components/Layout/Company';
import Recruiter from './components/Layout/Recruiter';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CandidatePublicView from './components/Candidate/CandidatePublicView';
import CandidateApplication from './components/Candidate/CandidateApplication';
import Candidate from './components/Layout/Candidate';

const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#f55e49',
        darker: '#053e85',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
      second: {
        main: '#053e85',
        contrastText: '#fff',
      }
    },
  });

function App() {
  
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
          
      <Fragment>
      
          <Routes>
            <Route index element={<MarketingPage />} />
            <Route path="company/*" element={<Company />} />
            <Route path="user/*" element={<Recruiter />} />
            <Route path="candidate/*" element={<Candidate />} />
          </Routes>
      
      </Fragment>
    
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
