import { Fragment } from "react"
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import classes from './Recruiter.module.css'
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { recruiterPublicProfile } from '../../store/recruiterProfile-actions';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileTile from "../UI/ProfileTile";
import ProfileContactTile from "../UI/ProfileContactTile";
import PublicIcon from '@mui/icons-material/Public';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArticleIcon from '@mui/icons-material/Article';
import WebIcon from '@mui/icons-material/Web';
import TwitterIcon from '@mui/icons-material/Twitter';
import ProfileReviews from "../UI/ProfileReviews";



const RecruiterPublicView = ()=>{

    const dispatch = useDispatch()

    const publicProfile = useSelector(state=>(state.recruiterProfile.recruiterPublicDetails))

    useEffect(()=>{
        const fetchDetails = async ()=>{
            await dispatch(recruiterPublicProfile(localStorage.getItem('user_id')))
        }
        
        fetchDetails()
        
    }, [])

    

    return (
        <Fragment>
            <CssBaseline />
      {publicProfile.recruiter_name&&<Container fixed>
        <Box sx={{ marginTop: '1em', paddingBottom: '4em', width:'fit-content', marginLeft:'auto', marginRight:'auto' }}>
            <Box sx={{ marginTop: '2em', display: 'inline-flex', width:'100%', marginLeft:'auto', marginRight:'auto' }}>
                <Box sx={{display: 'block', marginTop: '1em'}}>
                    <ProfileTile name={publicProfile.recruiter_name} email={publicProfile.email} profile_pic={publicProfile.profile_pic} created_at={publicProfile.created_at} reviews_count={publicProfile.number_of_reviews}/>
                    <ProfileContactTile name={publicProfile.recruiter_name} email={publicProfile.email} phone={publicProfile.phone}/>
                    
                </Box>
                <Box sx={{display: 'block', marginLeft:'6em'}}>
                    <div className={classes.publicProfileName}>About {publicProfile.recruiter_name}</div>
                    <div className={classes.publicProfileDetails}>
                        <PublicIcon />
                        <div>Lives in {publicProfile.city}, {publicProfile.state}</div>
                    </div>
                    <div style={{width:'100%', border:'0', borderTop:'1px solid #dddddd', marginTop:'2em', marginBottom:'2em'}}></div>
                    { publicProfile.linkedin_url &&
                    <div className={classes.publicProfileDetails}>
                        <LinkedInIcon />
                        <div><a target="_blank" style={{textDecoration:'none', color:'inherit'}} href={publicProfile.linkedin_url}>LinkedIn profile</a></div>
                    </div>
                    }
                    { publicProfile.resume_url &&
                    <div className={classes.publicProfileDetails}>
                        <ArticleIcon />
                        <div><a target="_blank" style={{textDecoration:'none', color:'inherit'}} href={publicProfile.resume_url}>Resume</a></div>
                    </div>
                    }
                    { publicProfile.personal_url &&
                    <div className={classes.publicProfileDetails}>
                        <WebIcon />
                        <div><a target="_blank" style={{textDecoration:'none', color:'inherit'}} href={publicProfile.personal_url}>Personal website</a></div>
                    </div>
                    } 
                    { publicProfile.twitter_url && 
                    <div className={classes.publicProfileDetails}>
                        <TwitterIcon />
                        <div><a target="_blank" style={{textDecoration:'none', color:'inherit'}} href={publicProfile.twitter_url}>Twitter account</a></div>
                    </div>}
                    

                    { publicProfile.description&&
                    <div>
                        <div style={{width:'100%', border:'0', borderTop:'1px solid #dddddd', marginTop:'2em', marginBottom:'2em'}}></div>
                        
                            <div>
                                {publicProfile.description}
                            </div>
                            

                        
                    </div>
                    }

                    <ProfileReviews reviews={publicProfile.reviews} name={publicProfile.recruiter_name} classes={classes}/>
                
                    
                </Box>
            </Box>
        </Box>
      </Container>}
        </Fragment>
    )
}

export default RecruiterPublicView