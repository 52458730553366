import { Fragment } from "react";
import Box from '@mui/material/Box';
import classes from './Listing.module.css'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import ListingCard from "./ListingCard";
import { getAllListings } from "../../store/recruiterListings-action";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';


const PublicListingContainer=()=> {

    const navigate = useNavigate()

    const companyListings = useSelector((state) => state.publicListings.allListings)
    const [page, setPage] = useState(1);
    const [queryParams, setQueryParams] = useState()
    const [allActive, setAllActive] = useState(true);
    const [engineeringActive, setEngineeringActive] = useState(false);
    const [designActive, setDesignActive] = useState(false);
    const [operationsActive, setOperationsActive] = useState(false);
    const loading = useSelector((state) => state.publicListings.loading);
    const error = useSelector((state) => state.publicListings.error);
    const [fetched, setFetched] = useState(false);
    const [currDepartment, setCurrDepartment] = useState("All")


    const dispatch = useDispatch()

    useEffect(()=>{
        filterListingData()
    }, [queryParams])



    const filterListingData = async () =>{
        await dispatch(getAllListings(queryParams))
        setFetched(true)
    }

    const handleDepartmentFilter = async (department) =>{
        setQueryParams(prevState => ({
            ...prevState,
            ["department"]: department
        }));
    }

    const handleListingSingleFilter = (event) =>{
        event.preventDefault()
        setCurrDepartment(event.target.value)
        setPage(1)
        var setDepartment = ""
        if (event.target.value!=="all"){
            setDepartment = event.target.value
        }
        setQueryParams(prevState => ({
            ...prevState,
            ["department"]: setDepartment
        }));
        
    }

    const handleListingFilter = (event) =>{
        event.preventDefault()
        
        if (event.target.value===""){
            setAllActive(true)
            setEngineeringActive(false)
            setDesignActive(false)
            setOperationsActive(false)
        }

        if (event.target.value==="engineering"){
            setAllActive(false)
            setEngineeringActive(true)
            setDesignActive(false)
            setOperationsActive(false)
        }

        if (event.target.value==="design"){
            setAllActive(false)
            setEngineeringActive(false)
            setDesignActive(true)
            setOperationsActive(false)
        }

        if (event.target.value==="operations"){
            setAllActive(false)
            setEngineeringActive(false)
            setDesignActive(false)
            setOperationsActive(true)
        }

        setPage(1)
        handleDepartmentFilter(event.target.value)
        
    }
    
    const PER_PAGE = 5;
    let count = 0;
    if (companyListings){
    count = Math.ceil(companyListings.length / PER_PAGE);
    }

    const handlePageChange = (event, page)=>{
        setPage(page)
    }

    
    const navToListing = (id)=>{
        navigate(`/user/listings/${id}`)
      }


      if(loading&&fetched){

        return <Fragment>
            <Box className={classes.listingsContainer}>
            <div className={classes.filterButtons}>
                    <button className={allActive ? classes.buttonActive : ""}  value="" onClick={handleListingFilter}>All</button>
                    <button className={engineeringActive ? classes.buttonActive : ""}  value="engineering" onClick={handleListingFilter}>Engineering</button>
                    <button className={designActive ? classes.buttonActive : ""}  value="design" onClick={handleListingFilter}>Design</button>
                    <button className={operationsActive ? classes.buttonActive : ""}  value="operations" onClick={handleListingFilter}>Operations</button>
                    
                </div>
                    </Box>
                    <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="rectangular" width="100%" height={60} />
        
        <Skeleton variant="rounded" width="100%" height={60}  />
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60}  />
    </Stack>
        </Fragment>;
      }


    
      if (loading) {
        return <Stack spacing={1} sx={{width:'60%', margin:'auto', marginTop:'5em'}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="text" sx={{ fontSize: '1rem', margin: 'auto'}} width="100%" />
        <Skeleton variant="rectangular" width="100%" height={60} />
        
        <Skeleton variant="rounded" width="100%" height={60}  />
        <Skeleton variant="rectangular" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={60}  />
    </Stack>
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      if (!companyListings || companyListings.length === 0) {
        return <Fragment>
            <Box className={classes.listingsContainer}>
            <div className={classes.filterButtons}>
                    <button className={allActive ? classes.buttonActive : ""}  value="" onClick={handleListingFilter}>All</button>
                    <button className={engineeringActive ? classes.buttonActive : ""}  value="engineering" onClick={handleListingFilter}>Engineering</button>
                    <button className={designActive ? classes.buttonActive : ""}  value="design" onClick={handleListingFilter}>Design</button>
                    <button className={operationsActive ? classes.buttonActive : ""}  value="operations" onClick={handleListingFilter}>Operations</button>
                    
                </div>
                <Box sx={{ minWidth: 120 }} className={classes.mobileFilter}>
                    <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{color:'#000000'}}>
                    Department
                    </InputLabel>
                    <NativeSelect
                    defaultValue={currDepartment}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleListingSingleFilter}
                    >
                    <option value={"all"}>All</option>
                    <option value={"engineering"}>Engineering</option>
                    <option value={"design"}>Design</option>
                    <option value={"operations"}>Operations</option>
                    </NativeSelect>
                    </FormControl>
                </Box>
                <div className={classes.noListingsContainer}>
                        <h3>No listings found</h3>
                    </div>
                    </Box>
        </Fragment>;
        
      }



    return (
        <Fragment>
            <Box className={classes.listingsContainer}>
            <div className={classes.filterButtons}>
                    <button className={allActive ? classes.buttonActive : ""}  value="" onClick={handleListingFilter}>All</button>
                    <button className={engineeringActive ? classes.buttonActive : ""}  value="engineering" onClick={handleListingFilter}>Engineering</button>
                    <button className={designActive ? classes.buttonActive : ""}  value="design" onClick={handleListingFilter}>Design</button>
                    <button className={operationsActive ? classes.buttonActive : ""}  value="operations" onClick={handleListingFilter}>Operations</button>
                    
                </div>
                <Box sx={{ minWidth: 120 }} className={classes.mobileFilter}>
                    <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{color:'#000000'}}>
                    Department
                    </InputLabel>
                    <NativeSelect
                    defaultValue={currDepartment}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleListingSingleFilter}
                    >
                    <option value={"all"}>All</option>
                    <option value={"engineering"}>Engineering</option>
                    <option value={"design"}>Design</option>
                    <option value={"operations"}>Operations</option>
                    </NativeSelect>
                    </FormControl>
                </Box>
                <div>
                {companyListings.length>0&&companyListings.slice(page*5-5,page*5).map((listing)=>{
                        return(
                            <Box key={listing.id} className={classes.listingCard} onClick={() => navToListing(listing.id)}>
                                <ListingCard key={listing.id} {...listing} />
                            </Box>
                        )
                    })
                }
                </div>
        {companyListings.length>0&&<Stack sx={{margin: "2em 2em 0em 2em;"}} spacing={2}>
            <Pagination 
            count={count}
            page={page} 
            color="second"
            onChange={handlePageChange}
            className={classes.paginationNav} />
        </Stack>}
            </Box>
        </Fragment>
    );
}

export default PublicListingContainer