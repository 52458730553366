import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Auth.module.css';
import { Fragment } from 'react';


import { useSelector, useDispatch } from 'react-redux';
import { recruiterLogIn, recruiterGetFullProfile } from '../../store/recruiterProfile-actions';
import { useState } from 'react';




const RecruiterSignIn = () =>{
    const dispatch = useDispatch();
    const recruiterProfile = useSelector((state) => state.recruiterProfile);

    const [recruiterEmail, setRecruiterEmail] = useState('')
    const [recruiterPassword, setRecruiterPassword] = useState('')

    const handleRecruiterSignIn = async (event) =>{
        event.preventDefault()
        await dispatch(recruiterLogIn(recruiterEmail,recruiterPassword))
        await dispatch(recruiterGetFullProfile())
        setRecruiterEmail('')
        setRecruiterPassword('')
    }

    const handleRecruiterEmailOnChange = (event) =>{
        event.preventDefault()
        setRecruiterEmail(event.target.value)
    }

    const handleRecruiterPasswordOnChange = (event) =>{
        event.preventDefault()
        setRecruiterPassword(event.target.value)
    }

    return (
        <Fragment>
        {!(recruiterProfile.recruiterLoggedIn)&&<Container sx={{display: 'flex', marginTop: '4em'}}>
            
    <Box className="signUpForm"
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        marginLeft:"auto",
        marginRight:'auto',
        marginTop: "4em",
        marginBottom: "4em",
        width: "fit-content"
      }}
      noValidate
      autoComplete="off"
    >
        <h2>Log In</h2>
        <TextField
          required
          id="outlined-required"
          label="Email Adress"
          onChange={handleRecruiterEmailOnChange}
          value={recruiterEmail}
        />
        <TextField
          required
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={handleRecruiterPasswordOnChange}
          value={recruiterPassword}
        />

        <button onClick={handleRecruiterSignIn} className={classes.button}>
      <span>Sign In</span>
    </button>
        
                
    </Box>

        </Container>}
        </Fragment>
    )
}

export default RecruiterSignIn