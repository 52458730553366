import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import classes from './Auth.module.css';
import { useState } from 'react';
import { updateCandidateProfile } from '../../store/candidateProfile-actions';
import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';



const CandidateSignUp = () =>{

  var BASE_DOMAIN = "https://api.prod.hyrewhiz.com"
  const LOCAL_DOMAIN = "http://0.0.0.0:8000"
  const TEST_DOMAIN = "https://api.test.hyrewhiz.com"

  if (process.env.REACT_APP_ENV==='local'){
    BASE_DOMAIN=LOCAL_DOMAIN
  }
  else if (process.env.REACT_APP_ENV==='test'){
    BASE_DOMAIN=TEST_DOMAIN
  }


    const dispatch = useDispatch();
    const profile = useSelector((state) => state.candidateProfile.candidateDetails);

    const [candidateEmail, setcandidateEmail] = useState("")
    const [candidateFirstName, setcandidateFirstName] = useState("")
    const [candidateLastName, setcandidateLastName] = useState("")
    const [candidatePhone, setcandidatePhone] = useState("")
    const [candidateLinkedin, setcandidateLinkedin] = useState("")

    useEffect(()=>{
        if(profile.email){
        setcandidateEmail(profile.email)
        }
        if(profile.first_name){
        setcandidateFirstName(profile.first_name)
        }
        if(profile.last_name){
        setcandidateLastName(profile.last_name)
        }
        if(profile.phone){
        setcandidatePhone(profile.phone)
        }
        if(profile.linkedin_url){
        setcandidateLinkedin(profile.linkedin_url)
        }
    }, [profile])

    const handleEditCandidate = async (event)=>{
        event.preventDefault()

        // if (file) {
        //     const formData = new FormData();
        //     formData.append('profile_picture', file);
      
        //     try {
        //       const response = await axios.post(`${BASE_DOMAIN}/account/company/${localStorage.getItem('company_user_id')}/image/`, formData);
        //       console.log(response.data);
        //     } catch (error) {
        //       console.error('Error uploading profile picture:', error);
        //     }
        //   }

        dispatch(updateCandidateProfile(profile.id, candidateEmail, candidateFirstName, candidateLastName, candidatePhone, candidateLinkedin))
    }

    const handlecandidateEmailChange = (event) =>{
        event.preventDefault()
        setcandidateEmail(event.target.value)
    }

    const handlecandidateFirstNameChange = (event) =>{
      event.preventDefault()
      setcandidateFirstName(event.target.value)
    }

    const handlecandidateLastNameChange = (event) =>{
      event.preventDefault()
      setcandidateLastName(event.target.value)
    }

    const handlecandidatePhoneChange = (event) =>{
      event.preventDefault()
      setcandidatePhone(event.target.value)
    }

    const handlecandidateLinkedinChange = (event) =>{
        event.preventDefault()
        setcandidateLinkedin(event.target.value)
        }


    return (
        <Fragment>
        <Container> 
    <Box className="signUpForm"
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { m: 1, width: '100%' },
        marginLeft:"auto",
        marginRight:'auto',
        marginTop: "4em",
        marginBottom: "4em",
        width: "30%"
      }}
      noValidate
      autoComplete="off"
    >
        <TextField
          required
          id="outlined-required"
          label="First name"
          value={candidateFirstName}
          onChange={handlecandidateFirstNameChange}
        />
        <TextField
          required
          id="outlined-required"
          label="Last name"
          value={candidateLastName}
          onChange={handlecandidateLastNameChange}
        />
        <TextField
          required
          id="outlined-required"
          label="Work email"
          value={candidateEmail}
          onChange={handlecandidateEmailChange}
        />
        <TextField
          id="outlined-number-required"
          label="Phone number"
          value={candidatePhone}
          onChange={handlecandidatePhoneChange}
        />
        <TextField
            id="outlined-number-required"
            label="Linkedin"
            value={candidateLinkedin}
            onChange={handlecandidateLinkedinChange}
        />

<button onClick={handleEditCandidate} className={`${classes.button} ${classes.canButton}`}>
      <span>Update profile</span>
    </button>
                
    </Box>

        </Container>
        </Fragment>
    )
}

export default CandidateSignUp