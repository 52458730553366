import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import classes from './MainHeader.module.css';
import HyrewizLogo from '../../logo.png';
import {useNavigate} from "react-router-dom";


const CandidateMainHeader = ()=> {

  const navigate = useNavigate();

const navHomePage = ()=>{
    navigate('/')
}

  return (
    <AppBar position="static" sx={{ bgcolor: "#F3F2EF", boxShadow: "none", borderBottom: "2px solid #ededed;" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
              width:'11em',
              height:'4em',
              marginLeft: '2em',
              marginRight: '4em',
              fontSize: '12px',
            }} className={classes.logo}>
            <img style={{cursor:'pointer'}} onClick={navHomePage} src={HyrewizLogo} alt="Logo" />
            </Box>
          
          <Box sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              maxWidth: '10em'
            }} className={classes.logo}>
            <img style={{cursor:'pointer'}} onClick={navHomePage} src={HyrewizLogo} alt="Logo" />
            </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default CandidateMainHeader;
