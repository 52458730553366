import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import classes from './MainHeader.module.css';
import HyrewizLogo from '../../logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CompanyMobileMenu = () => {
    const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const profile = useSelector((state) => state.recruiterProfile);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      style={{ width: '100vw', height: '100vh', backgroundColor: '#fff' }}
    >
        <div className={classes.mobileHeaderTitle}>
            <div className={classes.mobileHeaderImage}>
                <img style={{cursor:'pointer'}} src={HyrewizLogo} alt="Logo"/>
            </div>
            <div className={classes.mobileHeaderClose}>
                <CloseIcon onClick={toggleDrawer(false)}/>
            </div>
        </div>
            
      <List>
        <ListItem button>
          <ListItemText primary="Sign In" onClick={()=>{navigate('/user')}}/>
        </ListItem>
        <ListItem button>
          <ListItemText primary="How it works" onClick={()=>{navigate('/')}}/>
        </ListItem>
        {/* Add more links here */}
      </List>
    </div>
  );

  return (
    <div className={classes.mobileDropDownSignOut}>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} style={{color:'#000', width:'100%'}}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      
      <Drawer anchor="top" open={isOpen} onClose={toggleDrawer(false)} sx={{top:'15%'}}>
      
        {list()}
      </Drawer>
    </div>
  );
};

export default CompanyMobileMenu;