import Layout from "./Layout"
import {Routes, Route } from "react-router-dom";
import RecruiterSignIn from "../Auth/RecruiterSignIn";
import RecruiterSignUp from "../Auth/RecruiterSignUp";
import RecruiterProfile from "../Recruiter/RecruiterProfile";
import { useDispatch, useSelector } from "react-redux";
import { autoSignIn } from "../../store/recruiterProfile-actions";
import { useEffect } from "react";
import ListingPublicView from "../Listing/ListingPublicView";
import RecruiterPublicView from "../Recruiter/RecruiterPublicView";
import CandidatePublicView from "../Candidate/CandidatePublicView";
import PublicListingContainer from "../Listing/PublicListingContainer";
import RecruiterPayment from "../Recruiter/RecruiterPayment";
import RecruiterSubscription from "../Recruiter/RecruiterSubscription";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import RecruiterAccount from "../Recruiter/ReruiterAccount";
import RecruiterListingsContainer from "../Listing/RecruiterListingsContainer";
import RecruiterPayout from "../Recruiter/RecruiterPayout";


const Recruiter = () =>{

  const stripePromise = loadStripe('pk_test_51Mwl1zGjI5m9XztINrEq107Las1svApslotokXI6o04qs99fYVAoErD5PS1hiT0d7cdKSoeXPKFL2iKgrez3Y35m006ejXbOEi');

  const dispatch = useDispatch()
  const profile = useSelector((state) => state.recruiterProfile);
  

    useEffect(() => {
      if(localStorage.getItem('user_id')){
        dispatch(autoSignIn());
      }
      }, [dispatch]);    

    return (
        <Layout recruiterActive={true}>
          <Routes>
          {!profile.recruiterLoggedIn&&<Route index element={<RecruiterSignIn />} />}
          {profile.recruiterLoggedIn&&<Route index element={<PublicListingContainer />} />}
          {profile.recruiterLoggedIn&&<Route path="listings/:listingId" element={<ListingPublicView />} />}
            <Route path="/:recruiterId" element={<RecruiterPublicView />} />
            <Route path="signup" element={<RecruiterSignUp />} />
            <Route path="profile" element={<RecruiterProfile />} />
            <Route path="public" element={<RecruiterPublicView />} />
            <Route path="account" element={<RecruiterAccount />} />
            <Route path="payout" element={<Elements stripe={stripePromise}><RecruiterPayout recruiter_id={localStorage.getItem('user_id')}/></Elements>} />
            <Route path="candidate/:candidateId" element={<CandidatePublicView />} />
            <Route path="listings" element={<PublicListingContainer />} />
            <Route path="listings/all" element={<RecruiterListingsContainer />} />
            <Route path="payments" element={<RecruiterPayment recruiter_id={localStorage.getItem('user_id')} />} />
            <Route path="subscription" element={<Elements stripe={stripePromise}><RecruiterSubscription recruiter_id={localStorage.getItem('user_id')}/></Elements>} /> 
          </Routes>
        </Layout>
    )
} 

export default Recruiter